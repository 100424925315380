import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

import GoogleSearchInsights from './GoogleSearchInsights';
import { getDataValueFromElement } from '@/js/utils';
import { useQuery } from 'react-query';

const App = () => {
  const currentGscStatus = getDataValueFromElement(
    'body-root',
    'data-gsc-status',
  );
  const testSummaryElement = document.getElementById('test-summary');
  const testId =
    testSummaryElement?.attributes?.getNamedItem('data-test-id')?.value;
  const location = useLocation();

  const { data } = useQuery(['testResults', testId], async () => {
    const response = await fetch(
      `/api/webapp/test_detail/${testId}/result_data/`,
    );
    const data = await response.json();

    if (data.status === 'ok') {
      return data;
    } else {
      throw new Error('Error fetching test results');
    }
  });

  const isSeo =
    location.search.includes('?tab=SEO Test Results') ||
    data?.results?.results_type === 'seo';

  if (testSummaryElement && currentGscStatus === 'ready' && isSeo) {
    const element = document.getElementById('test-google-search-insights');

    if (!element) {
      return null;
    }

    return ReactDOM.createPortal(
      <GoogleSearchInsights testId={testId} />,
      element,
    );
  }

  return null;
};

export default App;
