import { getDataValueAsBoolFromElement } from '@/js/utils';
import { useQuery } from 'react-query';
import { useState } from 'react';

export const useTestResults = (testId: string) => {
  const [missingResultsError, setMissingResultsError] = useState(false);

  const {
    isError: fetchResultsError,
    data: results,
    isLoading: isResultsLoading,
  } = useQuery(['testResults', testId], async () => {
    setMissingResultsError(false);

    const response = await fetch(
      `/api/webapp/test_detail/${testId}/result_data/`,
    );
    const data = await response.json();

    if (data.status === 'ok') {
      return data;
    } else {
      throw new Error('Error fetching test results');
    }
  });

  const likelyTooEarlyForData = getDataValueAsBoolFromElement(
    'test-results',
    'data-likely-too-early-for-results',
  );

  if (!isResultsLoading && !fetchResultsError && results) {
    if (!results?.results?.complete_state) {
      if (likelyTooEarlyForData) {
        console.log(
          'There are no results for this test. This is due to the test only being recently published.',
        );
      } else {
        console.log('There was an error getting the test results');
        if (!missingResultsError) {
          setMissingResultsError(true);
        }
      }
    }
  }

  return {
    isError: fetchResultsError || missingResultsError,
    result: results?.results?.complete_state,
  };
};
