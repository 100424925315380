import React from 'react';

interface BaseCard extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className: string;
  light: boolean;
  stacked: boolean;
}

const BaseCard = ({
  children,
  className,
  light,
  stacked,
  ...rest
}: BaseCard) => {
  let backgroundColour = light ? 'bg-white' : 'bg-sp-neutral-50';
  let flex = stacked ? 'flex-col' : '';

  return (
    <div
      className={`sp-card-wrapper-base ${backgroundColour} ${flex} ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

BaseCard.defaultProps = {
  className: '',
  light: false,
  stacked: true,
};

export default BaseCard;
