import React, { createContext } from 'react';

export type IToast = {
  id: string;
  content: string;
  variant: 'warning' | 'info' | 'success' | 'error';
};

export type IToastContext = {
  warning: (content: string) => void;
  info: (content: string) => void;
  success: (content: string) => void;
  error: (content: string) => void;
  remove: (id: string) => void;
};

const ToastContext = createContext<IToastContext | null>(null);

export default ToastContext;
