import { escapeHTML, getBaseURL, getCookie, postEmpty } from './utils';
import { makeModal } from './modals';

function getDefaultHeaders() {
  return new Headers({
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    'X-CSRFToken': getCookie('csrftoken') || '',
  });
}

const toggleText = (element, textA, textB) => {
  if (element.textContent == textA) {
    element.textContent = textB;
  } else {
    element.textContent = textA;
  }
};

const toggleVisibility = (element) => {
  element.classList.toggle('hidden');
};

const makeVisible = (element) => {
  element.classList.remove('hidden');
};

const makeInvisible = (element) => {
  element.classList.add('hidden');
};

document.addEventListener('DOMContentLoaded', () => {
  // find all elements with a data-action of toggle-visibility
  const toggleVisibilityElements = document.querySelectorAll(
    "[data-action~='toggle-visibility']",
  );
  toggleVisibilityElements.forEach((element) => {
    // When the mouse enters the given element set the element identified by the
    // given data-element to be visible.
    // When the mouse leaves that element, hide it
    const parentElement = element as HTMLElement;
    const elementIDToToggle = parentElement.dataset.element;
    const mouseAction = parentElement.dataset.mouseAction;
    const stayOpen = parentElement.dataset.stayOpen;
    const startingText = parentElement.textContent;
    const onClickText = parentElement.dataset.onClickText;
    const elementToToggle = document.getElementById(elementIDToToggle ?? '');

    if (mouseAction === 'hover') {
      parentElement.addEventListener(
        'mouseenter',
        () => makeVisible(elementToToggle),
        false,
      );
      parentElement.addEventListener(
        'mouseleave',
        () => makeInvisible(elementToToggle),
        false,
      );
    } else {
      // default to toggling based on clicking on the parent element

      parentElement.addEventListener(
        'click',
        () => toggleVisibility(elementToToggle),
        false,
      );

      if (onClickText !== undefined) {
        parentElement.addEventListener(
          'click',
          () => toggleText(parentElement, startingText, onClickText),
          false,
        );
      }

      if (stayOpen === undefined || !stayOpen) {
        elementToToggle?.addEventListener(
          'mouseleave',
          () => makeInvisible(elementToToggle),
          false,
        );
      }
    }
  });

  // handle a click on a enabled toggle
  function handleEnabled(element) {
    const toggleParent = element.target.closest(
      "[data-action~='handle-enabled']",
    );
    const toggleSlider = toggleParent.children[0];

    const wasEnabled = toggleParent.dataset.state === 'on';
    const nowEnabled = wasEnabled ? 'False' : 'True';
    fetch(toggleParent.dataset.postUrl, {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: new URLSearchParams(`element_name=enabled&value=${nowEnabled}`),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.status != 'OK') {
          // The update on the backend hasn't worked.
          // We'll put the slider back to where it was, but make the background
          // red to show there was an error
          // TODO - we should standardise how we handle this - e.g. we should potentially
          // pop up a message box.

          if (wasEnabled) {
            toggleSlider.classList.add('translate-x-4');
            toggleSlider.classList.remove('translate-x-0');

            toggleParent.classList.remove('bg-sp-neutral-100');
            toggleParent.classList.remove('bg-sp-primary-600');
            toggleParent.classList.add('bg-red-700');
          }
        }
      })
      .catch((e) => {
        // show error notification for 3 seconds
        const ruleID = toggleParent.dataset.ruleId;
        const adjusterID = toggleParent.dataset.adjusterId;
        const notification = document.getElementById(`notification-${ruleID}`);
        const notificationMessage = document.getElementById(
          `notification-message-${ruleID}`,
        );
        notification?.classList.add('show');
        if (notificationMessage) {
          notificationMessage.innerText = adjusterID
            ? `Failed to toggle step in rule ${ruleID}`
            : `Failed to toggle rule ${ruleID}`;
        }

        setTimeout(() => {
          notification?.classList.remove('show');
        }, 3000);
      });

    // toggle the 4 classes that show whether the slider is enabled or not
    toggleParent.classList.toggle('bg-sp-neutral-100');
    toggleParent.classList.toggle('bg-sp-primary-600');
    toggleSlider.classList.toggle('translate-x-4');
    toggleSlider.classList.toggle('translate-x-0');

    // update the data-state attribute on the toggle
    if (wasEnabled) {
      toggleParent.dataset.state = 'off';
    } else {
      toggleParent.dataset.state = 'on';
    }
  }

  // find all elements with a data-action of toggle-visibility
  const handleEnabledElements = document.querySelectorAll(
    "[data-action~='handle-enabled']",
  );
  handleEnabledElements.forEach((element) => {
    // run the handleEnabled function on click for each element
    let htmlElement = element as HTMLElement;
    htmlElement.onclick = handleEnabled;
  });

  // create the tooltip for the given element
  function createTooltip(element) {
    const targetElement = element.target.closest("[data-action~='tooltip']");
    const tooltipText = targetElement.dataset.tooltip;
    const tooltipPosition = targetElement.dataset.tooltipPosition
      ? targetElement.dataset.tooltipPosition
      : 'bottom-0 right-0';

    const tooltip = `<div \
    class="tooltip sp-tooltip top ${tooltipPosition}">\
    ${tooltipText}\
  </div>`;

    targetElement.insertAdjacentHTML('beforeend', tooltip);
  }

  function removeTooltip(element) {
    const targetElement = element.target.closest('[data-action~=tooltip]');
    const tooltipElement = targetElement.querySelector('div.tooltip');
    if (tooltipElement) {
      tooltipElement.remove();
    }
  }

  const tooltipElements = document.querySelectorAll('[data-action~=tooltip]');

  tooltipElements.forEach((element) => {
    let htmlElement = element as HTMLElement;
    htmlElement.onmouseenter = createTooltip;
    htmlElement.onclick = removeTooltip;
    htmlElement.onmouseleave = removeTooltip;
  });

  // handle the move actions on rules and adjusters
  function handleMoveAction(element) {
    const targetElement = element.target.closest('[data-action~=move]');
    const params = new URLSearchParams(targetElement.dataset.postParams);
    const where = params.get('where');

    fetch(targetElement.dataset.postUrl, {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: params,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // TODO (with react?) - The reload here is a hack to keep the js simple
        // without it we'd have to change the "to_split_test" and "to_global" buttons
        // to be the opposite and disable the relevant buttons at the top / bottom of the
        // list
        location.reload();

        if (data.status != 'OK') {
          // The update on the backend hasn't worked.
          // TODO handle this
          console.log('Something went wrong - fix me');
        }
      });

    // make the button a watch icon until we reload the page
    const svgs = targetElement.getElementsByTagName('svg');
    Array.from(svgs).forEach((svg) => {
      (svg as HTMLElement).innerHTML =
        '\
      <circle cx="12" cy="12" r="7"> </circle>\
      <polyline points="12 9 12 12 13.5 13.5"> </polyline>\
      <path d="M16.51 17.35l-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7l.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83"></path>\
    ';
    });
  }

  const moveRuleElements = document.querySelectorAll('[data-action~=move]');
  moveRuleElements.forEach((element) => {
    let htmlElement = element as HTMLElement;
    htmlElement.onclick = handleMoveAction;
  });

  // handle modals
  const openModal: NodeListOf<Element> = document.querySelectorAll(
    '[data-action~=modal-open]',
  );

  const toggleModal = (modal: HTMLElement) => {
    const body: HTMLBodyElement | null = document.querySelector('body');
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
    body?.classList.toggle('modal-active');
  };

  // we set this as a global variable so it can be used to open the modal
  // and close it either when clicking on the overlay or when
  // clicking on the close button/ pressing ESC
  let targetModal: HTMLElement | null = null;

  openModal.forEach((element: Element) => {
    element.addEventListener('click', () => {
      const targetModalID: string | null = element.getAttribute('data-target');
      // the targetModal variable is initially set to the element specified in the openModal data target
      // it is then used below to add the remaining event listeners to the same element
      // the process will be repeated for any additional modals in the same page
      targetModal = document.getElementById(targetModalID ?? '');
      if (targetModal) {
        toggleModal(targetModal);
      }
    });
  });

  // close modal when overlay is clicked
  const overlay: NodeListOf<Element> = document.querySelectorAll(
    '[data-action~=modal-overlay]',
  );
  overlay.forEach((element: Element) => {
    element.addEventListener('click', () => {
      if (targetModal) {
        toggleModal(targetModal);
      }
    });
  });

  const closeModal: NodeListOf<Element> = document.querySelectorAll(
    '[data-action~=modal-close]',
  );

  closeModal.forEach((element: Element) => {
    element.addEventListener('click', () => {
      if (targetModal) {
        toggleModal(targetModal);
      }
    });
  });

  document.onkeydown = (event) => {
    let isEscape: boolean = false;

    if ('key' in event) {
      isEscape = event.key === 'Escape' || event.key === 'Esc';
    }
    if (isEscape && document.body.classList.contains('modal-active')) {
      if (targetModal) {
        toggleModal(targetModal);
      }
    }
  };

  // find all elements with a data-action of modal-confirm
  const modalConfirmElements = document.querySelectorAll(
    "[data-action~='modal-confirm']",
  );

  const handleModalConfirmClick = (event) => {
    event.preventDefault();

    const modalTrigger = event.target.closest('[data-action~=modal-confirm]');

    const modalURL = modalTrigger.dataset.modalConfirmHref;

    const modalTitle = modalTrigger.dataset.modalTitle
      ? modalTrigger.dataset.modalConfirmTitle
      : 'Are you sure?';

    const modalBodyText = modalTrigger.dataset.modalConfirmText
      ? escapeHTML(modalTrigger.dataset.modalConfirmText)
      : 'Are you sure? There is no undo!';
    const modalBodyTextWrapped = `<p class="py-2 px-4 tracking-wide">${modalBodyText}</p>`;

    const modalOKButtonText = modalTrigger.dataset.modalConfirmOkButtonText
      ? modalTrigger.dataset.modalConfirmOkButtonText
      : 'OK';

    const modalCancelButtonText = modalTrigger.dataset
      .modalConfirmCancelButtonText
      ? modalTrigger.dataset.modalConfirmCancelButtonText
      : 'Cancel';

    makeModal(
      modalTitle,
      modalBodyTextWrapped,
      modalOKButtonText,
      modalCancelButtonText,
      ({}) => {
        if (modalURL) {
          postEmpty(modalURL)
            .then((resp) => {
              location.reload();
            })
            .catch(() => console.log('failed'));
        } else {
          return new Promise(() => {});
        }
      },
    );
  };
  modalConfirmElements.forEach((element: Element) => {
    element.addEventListener('click', handleModalConfirmClick, false);
  });
});
