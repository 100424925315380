import React, { useState, useMemo, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { uid } from 'react-uid';
import ToastContext, { IToast } from './context';
import Toast from './Toast';

const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  const add = (content, variant) => {
    const id = uid(content);

    setToasts([...toasts, { id, content, variant }]);
  };
  const remove = (id) => setToasts(toasts.filter((toast) => toast.id !== id));

  const warning = (content) => add(content, 'warning');

  const info = (content) => add(content, 'info');

  const success = (content) => add(content, 'success');

  const error = (content) => add(content, 'error');

  const providerValue = useMemo(() => {
    return { warning, info, success, error, remove };
  }, [toasts]);

  return (
    <ToastContext.Provider value={providerValue}>
      {children}

      {createPortal(
        <>
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              remove={() => remove(toast.id)}
              variant={toast.variant}
            >
              {toast.content}
            </Toast>
          ))}
        </>,
        document.body,
      )}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
