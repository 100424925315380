export const capitalise = (words: string): string => {
  return words
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getName = (data: any[], slug) => {
  let selected = data.find((item) => item.slug === slug);
  return selected.name;
};
