import React, { Key, useState } from 'react';

import {
  LightCard,
  CardHeader,
  Icon,
  MetricsCard,
  Pill,
  ComboBox,
  Item,
  ToggleButton,
  NewFeatureBadge,
  Tooltip,
  Alert,
} from '@components';
import { HeadFootBlockContent } from '@components/layout';
import { RankingsSection } from './components/RankingsSection';
import { CtrSection } from './components/CtrSection';
import { DataCaptureSection } from './components/DataCaptureSection';
import { useQuery } from 'react-query';
import { getJson } from '@/js/utils';
import { CaptureRateData, CTRData, RankingData } from './types';
import { GSCKeyTakeAway } from './components/GSCKeyTakeAway';
import { useTestResults } from './hooks/useTestResults';

const GoogleSearchInsights = ({ testId }: { testId: string | undefined }) => {
  const [showTable, setShowTable] = useState(false);
  const [metric, setMetric] = useState<Key>('averageRank');

  const { result } = useTestResults(testId || '');

  const isValidTestResult = result === 'accepted' || result === 'rejected';

  const {
    isLoading: isLoadingCtr,
    error: errorCtr,
    data: dataCtr,
  } = useQuery<CTRData, Error>(
    ['GoogleInsightsCTR', testId],
    async () => {
      const url = `/api/webapp/test_detail/gsc_analysis/${testId}/click_through_rate`;
      const response = await getJson(url, {});
      if (response.metric_summary) {
        response.metric_summary = JSON.parse(response.metric_summary);
      }
      return response;
    },
    {
      retry: 1,
    },
  );

  const {
    isLoading: isLoadingCaptureRate,
    error: errorCaptureRate,
    data: dataCaptureRate,
  } = useQuery<CaptureRateData, Error>(
    ['GoogleInsightsCaptureRateSummary', testId],
    async () => {
      const url = `/api/webapp/test_detail/gsc_analysis/${testId}/capture_rate_summary`;
      const response = await getJson(url, {});
      if (response.data) {
        response.data = JSON.parse(response.data);
      }
      return response;
    },
    {
      retry: 1,
    },
  );

  const {
    isLoading: isLoadingRankings,
    error: errorRankings,
    data: dataRankings,
  } = useQuery<RankingData, Error>(
    ['GoogleInsightsRankings', testId],
    async () => {
      const url = `/api/webapp/test_detail/gsc_analysis/${testId}/rankings`;
      const response = await getJson(url, {});
      if (response.metric_summary) {
        response.metric_summary = JSON.parse(response.metric_summary);
      }
      return response;
    },
    {
      retry: 1,
    },
  );

  const isErrorOrLoading =
    errorCaptureRate ||
    errorCtr ||
    errorRankings ||
    isLoadingCaptureRate ||
    isLoadingCtr ||
    isLoadingRankings;

  if (isErrorOrLoading) {
    return null;
  }

  return (
    <LightCard>
      <CardHeader>
        <CardHeader.Content>
          <CardHeader.Title>
            <div className="flex items-center">
              Google Search Console Insights
              <span className="ml-2 flex">
                <NewFeatureBadge
                  id="google-search-console-insights"
                  hideAfter={new Date('2024-06-01')}
                  text="Beta"
                />
              </span>
            </div>
          </CardHeader.Title>
          <CardHeader.Description>
            Explore advanced insights into Google Search Console Data{' '}
          </CardHeader.Description>
        </CardHeader.Content>
      </CardHeader>
      {isValidTestResult ? (
        <>
          <HeadFootBlockContent>
            <p className="text-sp-neutral-900 mb-5 text-lg font-semibold leading-7">
              Observed movement in key metrics
            </p>
            <div className="-my-2">
              <MetricsCard.Group>
                <MetricsCard
                  title={
                    <>
                      Relative Rank Change
                      <Tooltip message="The relative change in rank between buckets">
                        <Icon
                          name="question"
                          size="md"
                          className="ml-1 inline-block align-middle"
                          color="transparent"
                        />
                      </Tooltip>
                    </>
                  }
                  value={dataRankings?.relative_change.toFixed(2) || ''}
                  pill={
                    <Pill
                      colour={
                        (dataRankings?.relative_change ?? 0) > 0
                          ? 'red'
                          : 'green'
                      }
                      rightIcon={
                        (dataRankings?.relative_change ?? 0) > 0
                          ? 'FiArrowUp'
                          : 'FiArrowDown'
                      }
                      onIconClick={() => {}}
                      size="sm"
                    />
                  }
                  colour="green"
                ></MetricsCard>
                <MetricsCard
                  title={
                    <>
                      Relative CTR Change
                      <Tooltip message="The relative change in CTR between buckets">
                        <Icon
                          name="question"
                          size="md"
                          className="ml-1 inline-block align-middle"
                          color="transparent"
                        />
                      </Tooltip>
                    </>
                  }
                  value={dataCtr?.relative_change.toFixed(2) || ''}
                  pill={
                    <Pill
                      colour={
                        (dataCtr?.relative_change ?? 0) > 0 ? 'green' : 'red'
                      }
                      rightIcon={
                        (dataCtr?.relative_change ?? 0) > 0
                          ? 'FiArrowUp'
                          : 'FiArrowDown'
                      }
                      onIconClick={() => {}}
                      size="sm"
                    />
                  }
                  colour="green"
                ></MetricsCard>
              </MetricsCard.Group>
            </div>
            <GSCKeyTakeAway
              relativeCtrChange={dataCtr?.relative_change}
              relativeRankChange={dataRankings?.relative_change}
              testId={testId}
            />
            <div className="mt-10">
              <p className="text-sp-neutral-900 text-lg font-semibold leading-7">
                Explore GSC Metrics
              </p>
            </div>
          </HeadFootBlockContent>
          <HeadFootBlockContent>
            <div className="mb-2 flex items-center justify-between">
              <ComboBox
                label="Metric"
                showBorder
                selectedKey={metric}
                onSelectionChange={(item: Key) => setMetric(item)}
              >
                <Item key="averageRank">Average Rank</Item>
                <Item key="ctr">Average CTR</Item>
              </ComboBox>

              <div className="flex flex-col gap-3 md:flex-row">
                <ToggleButton
                  checked={showTable}
                  onClickFunction={(e) => setShowTable(!showTable)}
                  onClickParameters={[]}
                />
                <p className="text-sm text-gray-700">
                  View as <br /> metric table
                </p>
              </div>
            </div>

            {metric === 'averageRank' && (
              <RankingsSection
                showTable={showTable}
                data={dataRankings}
                isLoading={isLoadingRankings}
                error={errorRankings}
              />
            )}

            {metric === 'ctr' && (
              <CtrSection
                showTable={showTable}
                data={dataCtr}
                isLoading={isLoadingCtr}
                error={errorCtr}
              />
            )}

            <Alert variant="info">
              <p className="text-sp-neutral text-sm">
                The charts show average rank or CTR per bucket in the pre and
                post intervention periods. All average rank and CTR data is
                normalized by impressions. <br />{' '}
                <span className="text-sp-primary font-semibold">Note:</span>{' '}
                When evaluating GSC data, it's important to take into account
                factors that might explain why the observed changes in rank/CTR
                may not accurately reflect the actual impact on organic traffic.{' '}
                <a
                  href="/docs/searchpilot"
                  className="text-sp-primary underline"
                  target="_blank"
                >
                  See our documentation for more information.
                </a>
              </p>
            </Alert>

            <p className="text-sp-neutral-900 my-10 text-lg font-semibold">
              Explore Data Captured
            </p>

            <DataCaptureSection
              data={dataCaptureRate}
              isLoading={isLoadingCaptureRate}
              error={errorCaptureRate}
            />
          </HeadFootBlockContent>
        </>
      ) : (
        <HeadFootBlockContent>
          <p className="text-sp-neutral-900 mb-10 text-lg font-medium leading-7">
            Observed movement in key metrics
          </p>
          <div className="text-sp-neutral bg-sp-neutral-50 mb-4 rounded-lg p-4">
            <p className="text-sp-neutral">
              GSC insights are only available for completed tests that are
              statistically significant.
            </p>
          </div>
        </HeadFootBlockContent>
      )}
    </LightCard>
  );
};

export default GoogleSearchInsights;
