import * as React from 'react';
import type { ComboBoxProps } from '@react-types/combobox';
import { useComboBoxState } from 'react-stately';
import { useComboBox, useFilter, useButton } from 'react-aria';

import { ListBox } from '../../navigation/Topbar/ListBox';
import { Popover } from '../../navigation/Topbar/Popover';
import { Icon } from '@components';

export { Item, Section } from 'react-stately';

interface ComboBoxPropsWithClassName<T> extends ComboBoxProps<T> {
  showBorder?: boolean;
}

const ComboBox = <T extends object>(props: ComboBoxPropsWithClassName<T>) => {
  let { contains } = useFilter({ sensitivity: 'base' });
  let state = useComboBoxState({ ...props, defaultFilter: contains });

  let buttonRef = React.useRef(null);
  let inputRef = React.useRef(null);
  let listBoxRef = React.useRef(null);
  let popoverRef = React.useRef(null);

  let {
    buttonProps: triggerProps,
    inputProps,
    listBoxProps,
    labelProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  let { buttonProps } = useButton(triggerProps, buttonRef);

  return (
    <div className="inline-flex flex-col relative w-60">
      <label
        {...labelProps}
        className="block text-sm font-medium text-gray-700 text-left"
      >
        {props.label}
      </label>
      <div
        className={`relative flex inline-flex flex-row rounded-lg py-2 overflow-hidden  ${
          props.showBorder
            ? 'border border-solid border-sp-neutral-300 shadow-sm'
            : 'border border-transparent focus:border-solid'
        } ${
          state.isFocused
            ? 'border border-solid ring ring-sp-primary-100 border-sp-primary-300'
            : 'hover:border-solid hover:border-sp-neutral-100 hover:ring hover:ring-sp-neutral-50'
        } `}
      >
        <input
          {...inputProps}
          ref={inputRef}
          className="pl-3.5 outline-none w-full"
        />

        <button
          {...buttonProps}
          ref={buttonRef}
          className={`pl-1 pr-3.5 cursor-default ${
            state.isFocused
              ? 'border-primary-500 text-sp-primary'
              : 'border-sp-neutral-300 text-sp-neutral-500'
          }`}
        >
          {state.isFocused && state.isOpen ? (
            <Icon aria-hidden="true" name="chevronUp" />
          ) : (
            <Icon aria-hidden="true" name="chevronDown" />
          )}
        </button>
      </div>
      {state.isOpen && (
        <Popover
          popoverRef={popoverRef}
          triggerRef={inputRef}
          state={state}
          isNonModal
          placement="bottom start"
          className="w-60"
        >
          <ListBox
            {...listBoxProps}
            listBoxRef={listBoxRef}
            state={state}
            showCheck={true}
          />
        </Popover>
      )}
    </div>
  );
};

export default ComboBox;
