import React from 'react';
import { InfoCard } from '../ui-components/InfoCard';
import { Icon } from '@components';
import { useTestResults } from '../hooks/useTestResults';

const scenarios = {
  positiveSeo: {
    positiveCtrPositiveRank: {
      title: 'Potential CTR improvement',
      description:
        "Based on observed data, this change may have had a positive impact on CTR for the variant group relative to the control. There was an observed increase in average rank, meaning that rankings were worse for variant pages compared to control. These two things together imply that the test harmed rankings to some extent, but that was outweighed by an improvement to users' likelihood to click on your pages in search results.",
      link: {
        text: 'See our documentation for more guidance on interpreting this data.',
      },
    },
    negativeCtrNegativeRank: {
      title: 'Potential improvement in average rank',
      description:
        'Based on observed data, this change may have had a positive impact on average rank for the variant group relative to the control, without having a beneficial impact on CTR.',
      link: {
        text: 'See our documentation for more guidance on interpreting this data.',
      },
    },
    positiveCtrNegativeRank: {
      title: 'Potential improvement in CTR and average rank',
      description:
        'Based on observed data, this change may have had a positive impact on both average CTR and rank for the variant group relative to the control. Note that CTR usually goes up as rankings improve, so this does not necessarily imply that CTR would have been affected in isolation of rankings.',
      link: {
        text: 'See our documentation for more guidance on interpreting this data.',
      },
    },
    negativeCtrPositiveRank: {
      title:
        'Observed GSC data not well correlated with organic traffic result',
      description:
        'Despite the overall organic traffic impact of this test being positive, we have observed negative signals in the Search Console data. Organic traffic should always be seen as the primary metric for SEO tests, and there are multiple reasons the Search Console data may not align with it. New rankings (or lost rankings) can change both ranking position and CTR averages in the opposite direction to real world benefit (e.g. new low position rankings for high volume kws could drag down average rank and average CTR while total organic traffic goes up).',

      link: {
        text: 'See our documentation for more guidance on interpreting this data and why Search Console data may not align with the organic traffic result.',
      },
    },
  },
  negativeSeo: {
    positiveCtrPositiveRank: {
      title: 'Potential worsening of average rank',
      description:
        'Based on observed data, this change may have had a negative impact on average rank for the variant group relative to the control, meaning that average rank went up.',
      link: {
        text: 'See our documentation for more guidance on interpreting this data.',
      },
    },
    negativeCtrNegativeRank: {
      title: 'Potential decrease in average CTR',
      description:
        'Based on observed data, this change may have had a negative impact on average CTR for the variant group relative to the control, meaning that average CTR went down, while rankings may have improved. The fact the overall test result was negative implies that the CTR impact outweighed the rankings impact.',
      link: {
        text: 'See our documentation for more guidance on interpreting this data.',
      },
    },
    positiveCtrNegativeRank: {
      title:
        'Observed GSC data not well correlated with organic traffic result',
      description:
        'Despite the overall organic traffic impact of this test being negative, we have observed positive signals in the Search Console data. Organic traffic should always be seen as the primary metric for SEO tests, and there are multiple reasons the Search Console data may not align with it.',
      link: {
        text: 'See our documentation for more guidance on why Search Console data may not align with the organic traffic result.',
      },
    },
    negativeCtrPositiveRank: {
      title: 'Potential decrease in average CTR and rank',
      description:
        'Based on observed data, this change may have had a negative impact on average CTR and rank for the variant group relative to the control, meaning that average CTR went down and average rank went up. Note that CTR usually goes down as rankings worsen, so this does not necessarily imply that CTR would have been affected in isolation of rankings.',
      link: {
        text: 'See our documentation for more guidance on interpreting this data.',
      },
    },
  },
};

const getScenarioDetailKey = (
  isCtrChangePositive: boolean,
  isRankChangePositive: boolean,
) => {
  if (isCtrChangePositive) {
    return isRankChangePositive
      ? 'positiveCtrPositiveRank'
      : 'positiveCtrNegativeRank';
  } else {
    return isRankChangePositive
      ? 'negativeCtrPositiveRank'
      : 'negativeCtrNegativeRank';
  }
};

const documentationUrl = '/docs/searchpilot/google-search-console-insights';

const resultOutcomeMap = {
  accepted: 'positiveSeo',
  rejected: 'negativeSeo',
};

export const GSCKeyTakeAway = ({
  relativeCtrChange,
  relativeRankChange,
  testId,
}: {
  relativeCtrChange: number | undefined;
  relativeRankChange: number | undefined;
  testId: string | undefined;
}) => {
  const { result, isError } = useTestResults(testId || '');

  const outcome = resultOutcomeMap[result] || 'negativeSeo';

  const isCtrChangePositive = Boolean(relativeCtrChange ?? 0 > 0);
  const isRankChangePositive = Boolean(relativeRankChange ?? 0 > 0);

  const scenarioDetailKey = getScenarioDetailKey(
    isCtrChangePositive,
    isRankChangePositive,
  );
  const selectedScenario = scenarios[outcome]?.[scenarioDetailKey];

  if (isError) {
    return null;
  }

  if (!relativeCtrChange && !relativeRankChange) {
    return null;
  }

  if (!selectedScenario) {
    return null;
  }

  return (
    <InfoCard
      className="mt-2.5"
      title={selectedScenario?.title}
      description={selectedScenario?.description}
      icon={<Icon name="bulb" size="2xl" />}
      link={{
        text: selectedScenario?.link?.text,
        url: documentationUrl,
      }}
    />
  );
};
