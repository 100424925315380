import React from 'react';
import cn from 'classnames';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

interface OutlinedButtonProps extends Omit<ButtonBaseProps, 'className'> {
  variant: 'primary' | 'danger';
}

export const mapOutlinedVariant = {
  primary: `sp-btn-action-outline`,
  danger: `sp-btn-action-outline-danger`,
};

const OutlineButton = ({
  variant,
  children,
  ...restProps
}: OutlinedButtonProps) => {
  return (
    <ButtonBase
      {...restProps}
      className={cn(`border shadow-xs`, mapOutlinedVariant[variant])}
    >
      {children}
    </ButtonBase>
  );
};

export default OutlineButton;
