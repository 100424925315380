import React from 'react';

import { ComboBox, Item } from '@components';
import { trackClientEvent } from '@utils/june';

import Link from './Link';
import { Customer } from '../types';

type ClientMenuListProps = {
  clientList: Customer[];
  canEditReports: boolean;
  handleChange: (string) => void;
  selectedClientSlug: string;
  currentActiveNav: string;
};

const ClientMenuList = ({
  clientList,
  canEditReports,
  handleChange,
  selectedClientSlug,
  currentActiveNav,
}: ClientMenuListProps): JSX.Element => {
  let isClientMenuItemShown = selectedClientSlug !== '';

  const selectedClient =
    selectedClientSlug &&
    clientList.find((client) => client.slug === selectedClientSlug);

  const canViewReportsPDF = selectedClient && selectedClient.can_view_reports;

  let clientMenuItems = [
    {
      name: 'Experiment Overview',
      path: `/cl-${selectedClientSlug}/dashboard/`,
      isShown: isClientMenuItemShown,
      isActive: currentActiveNav === 'client-dashboard',
      onClick: () => {
        trackClientEvent(selectedClientSlug, 'Experiment Overview Clicked', {
          tab: 'Summary',
        });
      },
    },
    {
      name: 'Archive',
      path: `/cl-${selectedClientSlug}/archive/`,
      isShown: isClientMenuItemShown,
      isActive: currentActiveNav === 'client-archive-dashboard',
      onClick: null,
    },
    {
      name: 'Reports',
      path: `/cl-${selectedClientSlug}/reports/`,
      isShown: isClientMenuItemShown && canViewReportsPDF,
      isActive: currentActiveNav === 'client-reports-viewer-dashboard',
      onClick: null,
    },
    {
      name: 'Reports Editor',
      path: `/cl-${selectedClientSlug}/reports/editor`,
      isShown: isClientMenuItemShown && canEditReports,
      isActive:
        window.location.pathname.endsWith('editor/') ||
        /\/reports\/editor\/edit\/\d+$/g.test(window.location.pathname),
      onClick: null,
    },
    {
      name: 'Settings',
      path: `/cl-${selectedClientSlug}/settings/`,
      isShown: isClientMenuItemShown,
      isActive: currentActiveNav === 'client-settings',
      onClick: null,
    },
  ];

  let clientMenuItemsList = clientMenuItems
    .filter((item) => item.isShown)
    .map((item, index) => {
      return (
        <Link
          key={`${item}-${index}`}
          href={selectedClientSlug !== null ? item.path : undefined}
          disabled={!selectedClientSlug}
          label={item.name}
          isActive={item.isActive}
          onClick={item.onClick ?? undefined}
        />
      );
    });

  let clientSelector;

  if (clientList !== undefined && clientList.length > 1) {
    clientSelector = (
      <ComboBox
        showBorder={false}
        placeholder="Select a customer"
        defaultItems={clientList}
        onSelectionChange={handleChange}
        selectedKey={selectedClientSlug}
        aria-labelledby={'Customers List Option'}
      >
        {(item) => <Item key={item.slug}>{item.name}</Item>}
      </ComboBox>
    );
  }

  if (clientList.length === 1) {
    clientSelector = clientList.map((client: Customer) => {
      return (
        <h4 key={client.slug} className="px-4 py-2 text-base font-semibold">
          {client.name}
        </h4>
      );
    });
  }

  return (
    <div>
      <div className="flex px-2 py-1">{clientSelector}</div>
      <ul>{clientMenuItemsList}</ul>
    </div>
  );
};

export default ClientMenuList;
