import React from 'react';

import { ComboBox, Item, Pill } from '@components';

import Link from './Link';
import { SimpleSite } from '../types';

type AccountMenuListProps = {
  pageAccountSlug: string;
  selectedAccountSlug: string;
  accountsList: SimpleSite[];
  setSelectedAccountSlug: (account: string) => void;
  currentActiveNav: string;
};

const navigateToAccountDashboard = (accountSlug) => {
  return window.location.assign(`/${accountSlug}/dashboard/`);
};

const mapPillColourToEnvironment = {
  production: 'green',
  staging: 'purple',
  qa: 'yellow',
  dev: 'gray',
  uat: 'blue',
  lower_envs: 'red',
};

const AccountMenuList = ({
  pageAccountSlug,
  selectedAccountSlug,
  accountsList,
  setSelectedAccountSlug,
  currentActiveNav,
}: AccountMenuListProps): JSX.Element => {
  let isAccountMenuShown;

  if (accountsList && accountsList.length == 1) {
    isAccountMenuShown = selectedAccountSlug !== '';
  } else {
    isAccountMenuShown =
      selectedAccountSlug !== '' && selectedAccountSlug === pageAccountSlug;
  }

  const handleChangeAccount = (slug) => {
    setSelectedAccountSlug(slug);
    if (slug !== null) {
      navigateToAccountDashboard(slug);
    }
  };

  let accountsSelector;

  if (accountsList && accountsList.length === 1) {
    accountsSelector = accountsList.map((account: SimpleSite) => (
      <h4 key={account.slug} className="text-base font-semibold px-4 py-2">
        {account.name}
      </h4>
    ));
  }

  if (accountsList && accountsList.length > 1) {
    accountsSelector = (
      <ComboBox
        showBorder={false}
        placeholder="Select a site"
        defaultItems={accountsList}
        onSelectionChange={handleChangeAccount}
        selectedKey={selectedAccountSlug}
        aria-labelledby={'Sites List Option'}
      >
        {(item: any) => (
          <Item key={item.slug} textValue={item.name}>
            <div className="w-full flex items-center justify-between">
              <div>{item.name}</div>
              <span className="mx-2">
                <Pill
                  text={item.environment}
                  colour={mapPillColourToEnvironment[item.environment]}
                />
              </span>
            </div>
          </Item>
        )}
      </ComboBox>
    );
  }

  let accountMenuItems = [
    {
      name: 'Site Dashboard',
      path: `/${selectedAccountSlug}/dashboard/`,
      isShown: isAccountMenuShown,
      isDisabled: !selectedAccountSlug,
      isActive: currentActiveNav === 'dashboard',
    },
    {
      name: 'Test Dashboard',
      path: `/${selectedAccountSlug}/testing/`,
      isShown: isAccountMenuShown,
      isDisabled: !selectedAccountSlug,
      isActive: currentActiveNav === 'test-dashboard',
    },
    {
      name: 'Datasets',
      path: `/${selectedAccountSlug}/datasets/list/`,
      isShown: isAccountMenuShown,
      isDisabled: !selectedAccountSlug,
      isActive: currentActiveNav === 'datasets',
    },
    {
      name: 'Settings',
      path: `/${selectedAccountSlug}/settings/`,
      isShown: isAccountMenuShown,
      isDisabled: !selectedAccountSlug,
      isActive: currentActiveNav === 'settings',
    },
    {
      name: 'Publish History',
      path: `/${selectedAccountSlug}/publish/history`,
      isShown: isAccountMenuShown,
      isDisabled: !selectedAccountSlug,
      isActive: currentActiveNav === 'publish_history',
    },
  ];

  const menuItems = accountMenuItems
    .filter((item) => item.isShown)
    .map((item, index) => {
      return (
        <Link
          key={`${item}-${index}`}
          href={selectedAccountSlug !== '' ? item.path : undefined}
          disabled={item.isDisabled}
          label={item.name}
          isActive={item.isActive}
        />
      );
    });

  return (
    <div>
      <div className="flex py-1 px-2">{accountsSelector}</div>
      <ul>{menuItems}</ul>
    </div>
  );
};

export default AccountMenuList;
