import React, { useRef } from 'react';
import { useTooltipTriggerState } from '@react-stately/tooltip';
import { useTooltipTrigger, useTooltip, mergeProps } from 'react-aria';

type TooltipProps = {
  children: React.ReactNode;
  message: string;
  tooltipPosition: 'top' | 'bottom' | 'left' | 'right';
};

const TooltipEl = ({ state, tooltipPosition, ...props }) => {
  let { tooltipProps } = useTooltip(props, state);

  return (
    <span
      className={`sp-tooltip ${tooltipPosition}`}
      {...mergeProps(props, tooltipProps)}
    >
      <p className="text-xs normal-case">{props.children}</p>
    </span>
  );
};

const Tooltip = ({
  children,
  message,
  tooltipPosition,
  ...props
}: TooltipProps) => {
  let state = useTooltipTriggerState(props);
  let ref = useRef(null);
  let { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);

  return (
    <span className="sp-tooltip-wrapper">
      <span ref={ref} {...triggerProps} className="cursor-pointer">
        {children}
      </span>
      {state.isOpen && (
        <TooltipEl
          state={state}
          tooltipPosition={tooltipPosition}
          {...tooltipProps}
        >
          {message}
        </TooltipEl>
      )}
    </span>
  );
};

Tooltip.defaultProps = {
  tooltipPosition: 'top',
  delay: 0,
};

export default Tooltip;
