import '../main';
import '../persistent_messages';
import '../data_actions';
import '../auto_submit_saml2_form';
import '../collapse_buttons';
import '../publish_message';
import '../starred_accounts';
import '../prevent_double_submit';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastProvider } from '../components/Toast';
import Navigation from '../navigation/Navigation';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { routes } from './routes';
import GoogleSearchInsights from './test_google_search_insights';
import { Announcements } from '@/js/components/Announcements/Announcements';

const TSRApp = React.lazy(() => import('./TSRApp'));
const RedisAdmin = React.lazy(() => import('../redis_delete_domain'));
const RedisKeysAdmin = React.lazy(() => import('../redis_database_key_list'));
const SparklinesApp = React.lazy(() => import('./sparklines'));
const CopyPasteArchiveApp = React.lazy(() => import('./copy_paste_archive'));
const ClientDashbdApp = React.lazy(() => import('./client_overview_dashboard'));
const ClientReportsEditorApp = React.lazy(() => import('./reports'));
const ClientArchiveApp = React.lazy(() => import('./archive'));
const StatusCakeApp = React.lazy(() => import('./statuscake'));
const StatusCakeManageApp = React.lazy(() => import('./management/statuscake'));
const UsersApp = React.lazy(() => import('../users'));
const SiteDatasetsApp = React.lazy(() => import('./datasets'));
const SiteSettingsApp = React.lazy(() => import('./settings'));
const ApiTokenApp = React.lazy(() => import('./management/api_token'));
const TwoFactorAuthSetupApp = React.lazy(() => import('./2fa_config'));
const TestResultsApp = React.lazy(() => import('./test_results'));
const TestSummaryApp = React.lazy(() => import('./test_summary'));
const AnalyticsConfigAdmin = React.lazy(() => import('./analytics_config'));
const DomainConfigApp = React.lazy(() => import('./domain_config'));
const RequestSectionApp = React.lazy(() => import('./request_section_v3'));
const LinearFullFunnelApp = React.lazy(() => import('./linear_full_funnel'));
const SetPasswordApp = React.lazy(() => import('./first_password_change'));
const ExperimentDatesApp = React.lazy(() => import('./experiment_dates'));
const GlobalIPAllowListsApp = React.lazy(
  () => import('./global_ip_allowlists'),
);
const ClientIPAllowListsApp = React.lazy(
  () => import('./client_ip_allowlists'),
);
const DownloadRulesApp = React.lazy(
  () => import('./management/download_site_rules'),
);
const InfrastructureMonitoringApp = React.lazy(
  () => import('../infrastructure-monitoring'),
);
const PublishDiff = React.lazy(() => import('./publish_diff'));
const Benchmarking = React.lazy(() => import('./benchmarking'));

const customers = JSON.parse(
  document.getElementById('client-hierarchy-data')?.textContent ?? '[]',
);

const sites = JSON.parse(
  document.getElementById('account-hierarchy-data')?.textContent ?? '[]',
);

const Root = () => {
  const navTarget = document.getElementById('navigation-react-target');

  return (
    <>
      {navTarget !== null ? (
        <Navigation customers={customers} sites={sites} />
      ) : null}

      <>
        <Announcements />

        <CopyPasteArchiveApp />
        <StatusCakeApp />
        <SparklinesApp />

        <Switch>
          <Route
            path={['/accounts', '/saml2']}
            render={() => (
              <Switch>
                <Route
                  path={routes.internal.auth_2fa_setup()}
                  render={() => <TwoFactorAuthSetupApp />}
                />
                <Route
                  path={routes.internal.auth_setPassword()}
                  render={() => <SetPasswordApp />}
                />
              </Switch>
            )}
          />

          <Route
            path="/management"
            render={() => (
              <Switch>
                <Route
                  path={routes.internal.management_statusCake()}
                  render={() => <StatusCakeManageApp />}
                />
                <Route
                  path={routes.internal.management_apiToken()}
                  render={() => <ApiTokenApp />}
                />
                <Route
                  path={routes.internal.management_downloadRules()}
                  render={() => <DownloadRulesApp />}
                />
                <Route
                  path={routes.internal.management_ipAllowLists()}
                  render={() => <GlobalIPAllowListsApp />}
                />
                <Route
                  path={routes.internal.management_redisKeysAdminInspect()}
                  render={() => null}
                />
                <Route
                  path={routes.internal.management_redisKeysAdmin()}
                  render={() => <RedisKeysAdmin />}
                />
                <Route
                  path={routes.internal.management_redisAdmin()}
                  render={() => <RedisAdmin />}
                />
                <Route
                  path={routes.internal.management_analyticsConfig()}
                  render={() => <AnalyticsConfigAdmin />}
                />
                <Route
                  path={routes.internal.management_linearFullFunnel()}
                  render={() => <LinearFullFunnelApp />}
                />
                <Route
                  path={routes.internal.management_publishDebug()}
                  render={() => <PublishDiff />}
                />
                <Route
                  path={routes.internal.management_benchmarking()}
                  render={() => <Benchmarking />}
                />
              </Switch>
            )}
          />

          <Route
            path="/cl-:clientSlug"
            render={() => (
              <Switch>
                <Route
                  path={routes.internal.client_overviewDashboard()}
                  render={() => <ClientDashbdApp />}
                />
                <Route
                  path={routes.internal.client_archive()}
                  render={() => <ClientArchiveApp />}
                />
                <Route
                  path={routes.internal.client_reportsEditor()}
                  render={() => <ClientReportsEditorApp />}
                />
                <Route
                  path={routes.internal.client_settingsUser()}
                  render={() => <UsersApp />}
                />
                <Route
                  path={routes.internal.client_ipAllowLists()}
                  render={() => <ClientIPAllowListsApp />}
                />
              </Switch>
            )}
          />

          <Route
            path={routes.internal.site_requestSection_create()}
            render={() => <RequestSectionApp />}
          />
          <Route
            path={routes.internal.site_originSettings_editConfig()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_originSettings_editOriginServers()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_originSettings_addOriginServers()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_failoverSettings()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_failoverSettings_ensureAllCreated()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_originSettings()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_infrastructureMonitoring()}
            render={() => <InfrastructureMonitoringApp />}
          />
          <Route path={routes.internal.site_editDomain()} render={() => null} />
          <Route
            path={routes.internal.site_editDomainConfig()}
            render={() => <DomainConfigApp />}
          />
          <Route
            path={routes.internal.site_section_variable_add()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_section_variable_addBulk()}
            render={() => <TSRApp />}
          />
          {/* edit has to come last, otherwise it will match the other routes */}
          <Route
            path={routes.internal.site_section_variable_edit()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_tests_edit()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_tests_create_resp()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_tests_create_req()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_publish_diff()}
            render={() => <PublishDiff />}
          />
          <Route
            path={routes.internal.site_publish_compare()}
            render={() => <PublishDiff />}
          />
          <Route
            path={routes.internal.site_publish_diff_v3()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_publish_history()}
            render={() => null}
          />
          <Route path={routes.internal.site_publish()} render={() => null} />
          <Route
            path={routes.internal.site_dashboard()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_tests_results_editDates()}
            render={() => <ExperimentDatesApp />}
          />
          <Route
            path={routes.internal.site_tests_results()}
            render={() => (
              <>
                <TestResultsApp />
                <TestSummaryApp />
                <GoogleSearchInsights />
              </>
            )}
          />
          <Route
            path={routes.internal.site_tests_dashboard()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_datasets()}
            render={() => <SiteDatasetsApp />}
          />
          <Route
            path={routes.internal.site_settings_site()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_settings_editNotes()}
            render={() => null}
          />
          <Route
            path={routes.internal.site_settings_users()}
            render={() => <UsersApp />}
          />
          <Route
            path={routes.internal.site_settings()}
            render={() => <SiteSettingsApp />}
          />
          <Route
            path={routes.internal.site_section_rule_adjuster_edit()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_section_rule_adjuster_add()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_section_rule_add()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_section_rule_edit()}
            render={() => <TSRApp />}
          />
          <Route
            path={routes.internal.site_section()}
            render={() => <TSRApp />}
          />
        </Switch>
      </>
    </>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const RootWithProviders = ({}) => (
  <BrowserRouter>
    {/* @ts-ignore this error will go away when we upgrade react-query – is related to how react's children prop is typed in newer react versions */}
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Root />
      </ToastProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

const rootContainer = document.getElementById('react-root');
if (!rootContainer) {
  throw new Error('Could not find root container');
}
const root = createRoot(rootContainer);
root.render(<RootWithProviders />);
