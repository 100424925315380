import React, { Children, isValidElement, cloneElement } from 'react';
import { SolidButton, IconButton } from '@components';

interface ButtonGroupBaseProps {
  children: React.ReactNode;
  groupType: string;
}

const ButtonGroupBase = ({ children, groupType }: ButtonGroupBaseProps) => {
  const validChildren = Children.toArray(children).filter((child, index) =>
    isValidElement(child),
  ) as any[];

  return (
    <div
      className={'flex relative h-10'}
      role="group"
      aria-label="button groups"
    >
      {Children.map(validChildren, (child, index) => {
        const isLast = index === validChildren.length - 1;
        const isFirst = index === 0;
        const isMiddle = !isFirst && !isLast;
        const middleChildren = validChildren.filter(
          (child, index) => index !== 0 && index !== validChildren.length - 1,
        );

        return (
          <>
            {isFirst &&
              cloneElement(child, {
                style: {
                  borderTopRightRadius: ' 0px',
                  borderBottomRightRadius: '0px',
                  boxShadow: 'none',
                  borderColor:
                    groupType === 'split' &&
                    child.props.variant === 'primary' &&
                    child.type === SolidButton &&
                    'white',
                },
              })}
            {isMiddle && middleChildren.length === 1
              ? cloneElement(child, {
                  style: {
                    borderRadius: '0px',
                    borderLeftWidth: '0px',
                    borderRightWidth: '0px',
                    boxShadow: 'none',
                  },
                })
              : null}
            {isMiddle &&
              middleChildren.length > 1 &&
              cloneElement(child, {
                style: {
                  borderRadius: '0px',
                  borderLeftWidth: '0px',
                  borderRightWidth: '1px',
                  boxShadow: 'none',
                },
              })}
            {isLast &&
              cloneElement(child, {
                style: {
                  width:
                    groupType === 'split' &&
                    child.type === IconButton &&
                    '40px',
                  border:
                    groupType === 'split' &&
                    child.props.kind === 'solid' &&
                    child.type === IconButton &&
                    `1px solid white`,
                  borderTopLeftRadius: ' 0px',
                  borderBottomLeftRadius: '0px',
                  boxShadow: 'none',
                },
              })}
          </>
        );
      })}
    </div>
  );
};

ButtonGroupBase.defaultProps = {
  groupType: null,
};

export default ButtonGroupBase;
