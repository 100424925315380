import React from 'react';
import { DataCaptureTable } from './DataCaptureTable';
import { Alert, LoadingSpinner } from '@/js/components';
import { CaptureRateData } from '../types';

export const DataCaptureSection = ({
  data,
  isLoading,
  error,
}: {
  data: CaptureRateData | undefined;
  isLoading: boolean;
  error: Error | null;
}) => {
  if (isLoading) {
    return (
      <div className="text-center">
        <LoadingSpinner size="md" />
      </div>
    );
  }

  if (error) {
    <Alert
      variant="error"
      message="There was an error fetching the data. Please contact SearchPilot."
    />;
  }

  return (
    <>
      <DataCaptureTable data={data} />
      <Alert variant="info">
        <p className="text-sp-neutral text-sm">
          Data queried from the GSC API is often sampled. Level of sampling may
          vary between domains, page types, and even tests on the same page
          type. Keep the amount of data captured in mind when evaluating the
          metrics above.
        </p>
      </Alert>
    </>
  );
};
