import React, { useState, useCallback, useRef } from 'react';
import { useQuery } from 'react-query';
import cn from 'classnames';

import {
  Modal,
  Icon,
  IconButton,
  ErrorText,
  LoadingSpinner,
  Pill,
  OutlineButton,
} from '@components';
import { getJson, getUserOS } from '@utils';
import { useOnKeyPress } from '@hooks';
import { FocusScope } from 'react-aria';

import { SearchBar, Item } from './SearchBar';
import { ListBox } from './ListBox';
import { getName } from '../Sidebar/helpers';
import { HeadFootBlockFooter } from '@components/layout';

const mapObjects = {
  rule: 'Rule',
  variable: 'Value',
  experiment: 'Experiment',
  adjuster: 'Step',
  section: 'Section',
  accountdataset: 'Dataset',
  client: 'Client',
  account: 'Site',
};

const FuzzySearchBar = ({
  pageClientSlug,
  pageAccountSlug,
  clientList,
  accountsList,
}) => {
  let userOS = getUserOS();
  let tooltipText;
  if (userOS === 'Mac OS') {
    tooltipText = 'Cmd+/ to search';
  } else {
    tooltipText = 'Control+/ to search';
  }

  const [modalOpen, setModalOpen] = useState(false);

  let clientName = pageClientSlug !== '' && getName(clientList, pageClientSlug);
  let accountName =
    pageAccountSlug !== '' && getName(accountsList, pageAccountSlug);

  let accountPrefix = `In Site`;
  let clientPrefix = `In Customer`;

  type SearchOption = {
    id: number;
    show: boolean;
    where: string;
    queryParams: {
      client_slug?: string;
      account_slug?: string;
      q?: string;
    }
  }

  // Different search options scenario based on clients and accounts list
  let searchOptions : SearchOption[] = [];

  if (clientList.length > 1) {
    searchOptions = [
      {
        id: 1,
        show: pageAccountSlug !== '',
        where: `${accountPrefix} ${accountName}`,
        queryParams: {
          client_slug: pageClientSlug,
          account_slug: pageAccountSlug,
        },
      },
      {
        id: 2,
        show: pageClientSlug !== '',
        where: `${clientPrefix} ${clientName}`,
        queryParams: {
          client_slug: pageClientSlug,
        },
      },
      {
        id: 3,
        show: clientList !== undefined && clientList.length > 1,
        where: 'In All SearchPilot',
        queryParams: {
          client_slug: '',
          account_slug: '',
        },
      },
    ];
  } else if (clientList.length === 1 && accountsList.length === 1) {
    let [account] = accountsList;

    searchOptions = [
      {
        id: 1,
        show: true,
        where: 'In All SearchPilot',
        queryParams: {
          client_slug: pageClientSlug,
          account_slug: account.slug,
        },
      },
    ];
  } else if (clientList.length === 1 && accountsList.length > 1) {
    searchOptions = [
      {
        id: 1,
        show: pageAccountSlug !== '',
        where: `${accountPrefix} ${accountName}`,
        queryParams: {
          client_slug: pageClientSlug,
          account_slug: pageAccountSlug,
        },
      },
      {
        id: 2,
        show: true,
        where: `All SearchPilot`,
        queryParams: {
          client_slug: pageClientSlug,
        },
      },
    ];
  }

  let [fieldState, setFieldState] = useState<{
    selectedKey: string;
    inputValue: string;
    items: any[];
    params: any;
    selectedItem: any;
  }>({
    selectedKey: '',
    inputValue: '',
    items: searchOptions,
    params: null,
    selectedItem: null,
  });

  //Shortcut keys to trigger search bar
  const keys = ['Meta', '/'];
  const handleKeyboardShortcut = useCallback(() => {
    setModalOpen((currentModalOpen) => !currentModalOpen);
    // clear search state when meta + / keys pressed
    setFieldState({
      selectedKey: '',
      inputValue: '',
      items: searchOptions,
      params: null,
      selectedItem: null,
    });
  }, [setModalOpen, setFieldState]);

  useOnKeyPress(keys, handleKeyboardShortcut);

  // Specify how each of the values should change when an option is selected from the list box
  let onSelectionChange = (key) => {
    setFieldState((prevState) => {
      let selectedItem = prevState.items.find((option) => option.id === key);

      return {
        inputValue: prevState.inputValue ?? '',
        selectedKey: key === '' ? prevState.items[0].id : key,
        items: searchOptions.filter((item) => item.show === true),
        params: selectedItem
          ? { ...selectedItem.queryParams, q: fieldState.inputValue }
          : { ...prevState.items[0].queryParams, q: fieldState.inputValue },
        selectedItem:
          prevState.selectedKey !== '' ? selectedItem : prevState.items[0],
      };
    });
  };

  // Specify how each of the ComboBox values should change when the input
  // field is altered by the user
  let onInputChange = (value) => {
    setFieldState((prevState) => {
      return {
        inputValue: value,
        selectedKey: prevState.selectedKey,
        items: searchOptions.filter((item) => item.show === true),
        params: prevState.params,
        selectedItem:
          prevState.selectedKey !== ''
            ? searchOptions.filter((item) => Number(item.id) === Number(prevState.selectedKey))
            : prevState.items[0],
      };
    });
  };

  // Show entire list if user opens the menu manually
  let onOpenChange = (isOpen, menuTrigger) => {
    if (menuTrigger === 'manual' && isOpen) {
      setFieldState((prevState) => {
        let selectedItem = searchOptions.filter(
          (item) => Number(item.id) === Number(prevState.selectedKey),
        );
        return {
          inputValue: prevState.inputValue,
          selectedKey: prevState.selectedKey,
          items: searchOptions.filter((item) => item.show === true),
          params: prevState.params,
          selectedItem: selectedItem,
        };
      });
    }
  };

  //Fetch data with params on trigger
  let url = '/api/webapp/search/';
  const { isLoading, error, data } = useQuery(
    ['fuzzySearchQueryResults', fieldState.params],
    async () => {
      return await getJson(url, fieldState.params);
    },
    { enabled: !!fieldState.params },
  );

  const [selectedKey, setSelectedKey] = useState('');
  const handleSelect = ({ currentKey }) => {
    setSelectedKey(currentKey);
    window.location.assign(currentKey);
  };

  const handleClose = () => {
    setModalOpen(false);
    setFieldState({
      selectedKey: '',
      inputValue: '',
      items: searchOptions,
      params: null,
      selectedItem: null,
    });
  };

  return (
    <>
      <IconButton.Ghost
        iconName="search"
        onClick={() => setModalOpen(true)}
        tooltipText={tooltipText}
        tooltipPosition="bottom"
      />
      <Modal
        open={modalOpen}
        onClose={() => handleClose()}
        defaultWidth={'w-fit'}
      >
        <div className="p-3">
          <SearchBar
            label="Search"
            items={fieldState.items}
            selectedKey={fieldState.selectedKey}
            inputValue={fieldState.inputValue}
            onOpenChange={onOpenChange}
            onSelectionChange={(key) => {
              if (fieldState.inputValue !== '') {
                onSelectionChange(key);
              }
            }}
            onInputChange={onInputChange}
          >
            {(item) => (
              <Item textValue={`${fieldState.inputValue}`}>
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="flex flex-row items-center grow">
                    <Icon name="search" aria-hidden="true" />{' '}
                    <p className="mx-2 break-normal">{fieldState.inputValue}</p>
                  </div>
                  <div className="bg-sp-neutral-200 flex text-xs text-sp-neutral rounded-md p-1">
                    {item.where} <Icon name="AiOutlineEnter" />
                  </div>
                </div>
              </Item>
            )}
          </SearchBar>
        </div>

        {isLoading ? (
          <div className="p-4 my-2 text-center">
            <p>Searching...</p>
            <LoadingSpinner />
          </div>
        ) : null}

        {data && data.results.length !== 0 ? (
          <FocusScope autoFocus>
            <div className="rounded-b-lg p-4">
              <h4 className="text-base font-semibold">
                <span className="mr-2">
                  Results ({data.results.length} of {data.total_results}){' '}
                </span>
                <Pill
                  text={`"${fieldState.inputValue}": ${fieldState.selectedItem.where}`}
                  colour={'purple'}
                />
              </h4>

              <ListBox
                label="Alignment"
                selectionMode="single"
                items={data.results}
                selectedKey={selectedKey}
                onSelectionChange={handleSelect}
              >
                {(item) => (
                  <Item textValue={item.title} key={item.link}>
                    <a className="text-sp-neutral" href={item.link}>
                      <span className="flex flex-col my-2">
                        <p className="text-base font-semibold">
                          {mapObjects[item.model_name]}: {item.title}
                        </p>
                        <p className="text-sm font-medium text-sp-neutral-500">
                          {item.description}
                        </p>
                      </span>
                    </a>
                  </Item>
                )}
              </ListBox>
            </div>
          </FocusScope>
        ) : null}

        {data && data.results.length === 0 && (
          <div className="flex justify-center items-center p-4 my-2 text-center">
            <p className="mr-2">No results for</p>
            <Pill
              text={`"${fieldState.inputValue}": ${fieldState.selectedItem?.where}`}
              colour={'purple'}
            />
          </div>
        )}

        {error && (
          <span className="p-4 my-2 text-center">
            <ErrorText text="Uh oh, Something went wrong" />
          </span>
        )}
        <HeadFootBlockFooter>
          <OutlineButton
            size="sm"
            variant="primary"
            onClick={() => handleClose()}
          >
            Esc
          </OutlineButton>
        </HeadFootBlockFooter>
      </Modal>
    </>
  );
};

export default FuzzySearchBar;
