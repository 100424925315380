import React from 'react';
import {
  Label,
  HelpText,
  ErrorText,
  SolidButton,
  ButtonGroup,
} from '@components';

interface InputFieldWithButtonProps {
  value: string;
  onChangeFunction: (...args) => void;
  onChangeParameters: any[];
  onClickFunction: () => void;
  label?: string;
  id?: string;
  buttonContent: string | JSX.Element;
  required?: boolean;
  placeholder?: string;
  helpText?: string | null;
  errorText?: string;
  type?: string;
  variant?: 'primary' | 'danger';
}

const InputFieldWithButton = ({
  value,
  onChangeFunction,
  onChangeParameters,
  onClickFunction,
  label,
  id,
  buttonContent,
  required,
  placeholder,
  helpText,
  errorText,
  type,
  variant,
}: InputFieldWithButtonProps) => {
  const errorCSS = errorText ? 'sp-input-field-with-error' : '';
  const inputStyle = `sp-input sp-input-field sp-input-field-with-button ${errorCSS}`;

  return (
    <>
      <Label label={label ?? ''} id={id ?? ''} />
      <ButtonGroup>
        <input
          value={value}
          onChange={(e) => {
            onChangeFunction(...onChangeParameters, e.target.value);
          }}
          id={id}
          className={inputStyle}
          required={required}
          placeholder={placeholder}
          type={type}
        />
        <SolidButton
          variant={variant ?? 'primary'}
          onClick={() => onClickFunction()}
          type="button"
        >
          {buttonContent}
        </SolidButton>
      </ButtonGroup>
      {errorText ? (
        <ErrorText text={errorText} />
      ) : (
        <HelpText>{helpText}</HelpText>
      )}
    </>
  );
};

InputFieldWithButton.defaultProps = {
  value: '',
  required: false,
  helpText: null,
  variant: 'primary',
};

export default InputFieldWithButton;
