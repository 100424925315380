import React from 'react';

import {
  Icon,
  LightCard,
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Tooltip,
} from '@components';
import { type CaptureRateData } from '../types';

export const DataCaptureTable = ({
  data,
}: {
  data: CaptureRateData | undefined;
}) => (
  <LightCard className="overflow-hidden">
    <Table>
      <thead>
        <TableHeaderCell
          colSpan={4}
          className="text-sp-neutral-900 bg-transparent text-lg font-medium capitalize"
        >
          GSC Data Capture Summary
        </TableHeaderCell>
        <TableHeaderRow>
          <TableHeaderCell className="capitalize">Bucket</TableHeaderCell>
          <TableHeaderCell className="capitalize">
            Path Capture Rate{' '}
            <Tooltip
              message="The percentage of paths we know about that are present in Search Console data."
              tooltipPosition="bottom"
            >
              <Icon
                name="question"
                size="md"
                className="mb-1 inline-block align-middle"
                color="transparent"
              />
            </Tooltip>
          </TableHeaderCell>
          <TableHeaderCell className="capitalize">
            Session Capture Rate{' '}
            <Tooltip
              message="The percentage of sessions we know about that correspond to pages which are present in Search Console data."
              tooltipPosition="bottom"
            >
              <Icon
                name="question"
                size="md"
                className="mb-1 inline-block align-middle"
                color="transparent"
              />
            </Tooltip>
          </TableHeaderCell>
        </TableHeaderRow>
      </thead>
      <tbody>
        <TableRow>
          <TableCell>Variant</TableCell>
          <TableCell>{data?.data?.variant?.path_capture_rate}</TableCell>
          <TableCell>{data?.data?.variant?.sessions_capture_rate}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Control</TableCell>
          <TableCell>{data?.data?.control?.path_capture_rate}</TableCell>
          <TableCell>{data?.data?.control?.sessions_capture_rate}</TableCell>
        </TableRow>
      </tbody>
    </Table>
  </LightCard>
);
