const collapseGroup = (iconContainer: Element, collapsableElement: Element) => {
  const plusSquareIcon: string =
    '<svg viewBox="0 0 24 24" class="w-4 h-4 mr-2" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>';

  const minusSquareIcon: string =
    '<svg viewBox="0 0 24 24" class="w-4 h-4 mr-2" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="8" y1="12" x2="16" y2="12"></line></svg>';

  if (iconContainer.hasAttribute('minus-square-icon')) {
    iconContainer.innerHTML = minusSquareIcon;
    collapsableElement.classList.remove('hidden');
  } else {
    iconContainer.innerHTML = plusSquareIcon;
    collapsableElement.classList.add('hidden');
  }
};

const collapseButtonDataAttr: string = "[data-action='collapse-button']";

const collapseButtons: NodeListOf<Element> = document.querySelectorAll(
  collapseButtonDataAttr,
);

collapseButtons.forEach((element: Element) => {
  element.addEventListener('click', () => {
    const groupHeader: Element | null = element.closest(
      "[data-type='group-header']",
    );

    const group: Element | null = element.closest("[data-type='group']");

    const collapsable: Element | null | undefined = group?.querySelector(
      "[data-action='collapsable']",
    );

    if (groupHeader && collapsable) {
      groupHeader
        .querySelectorAll(collapseButtonDataAttr)
        .forEach((element: Element) => {
          element.toggleAttribute('minus-square-icon');
          collapseGroup(element, collapsable);
        });
    }
  });
});

// collapse all the groups automatically
const groups: NodeListOf<Element> = document.querySelectorAll(
  "[data-type='group']",
);

groups.forEach((element: Element) => {
  const collapsable: Element | null = element.querySelector(
    "[data-action='collapsable']",
  );

  const collapseButtons: NodeListOf<Element> = element.querySelectorAll(
    collapseButtonDataAttr,
  );

  if (
    element.querySelectorAll("[data-type='added'], [data-type='deleted']")
      .length > 0 ||
    element.closest("[data-display='pure']")
  ) {
    collapseButtons.forEach((element: Element) => {
      if (collapsable) {
        collapseGroup(element, collapsable);
      }
    });
  } else {
    collapseButtons.forEach((element: Element) => {
      element.removeAttribute('minus-square-icon');
      if (collapsable) {
        collapseGroup(element, collapsable);
      }
    });
  }
});
