import { postObjectAsForm } from './utils';
import { makeModal } from './modals';
const csrf = require('./csrf');

const checkAuthy = (seconds: number, options) => {
  return fetch('/accounts/authy/check?seconds=' + seconds)
    .then((response) => response.json())
    .then((data) => {
      if (data.needs_authy) {
        options.yes(data.instruction);
      } else {
        options.no();
      }
    });
};

const checkValidAuthy = (code: string, options) => {
  return postObjectAsForm('/accounts/authy/ajax', { authy_token: code })
    .then((response) => response.json())
    .then((data) => {
      if (data.success == true) {
        options.valid();
      } else {
        options.invalid();
      }
    });
};

const submitHandler = (e) => {
  e.preventDefault();
  const form = e.target;
  // check if we should bypass
  const bypass = form.dataset.bypassAuthy === 'true';
  if (bypass) {
    return true;
  }
  var seconds = parseFloat(form.dataset.authy);
  checkAuthy(seconds, {
    yes: function (instruction) {
      const body = `
        <label class="block text-sm font-medium leading-5 w-full text-sp-neutral-900">${instruction}</label>
        <div class="sp-input-field-div-wrapper flex">
          <input type="text" name="code" class="sp-input sp-input-field " placeholder="" value="">
        </div>
      `;
      const onSubmit = ({ code }) => {
        if (code === false) return false;
        return checkValidAuthy(code, {
          valid: () => {
            csrf.replaceCSRFTokens();
            form.dataset.bypassAuthy = 'true';
            form.submit();
          },
          invalid: () => {
            // give time for the existing modal to clear before presenting the new
            // error message
            setTimeout(() => {
              makeModal('Error!', 'Incorrect code', 'Ok', 'Cancel', null);
            }, 50);
            return false;
          },
        });
      };
      makeModal('Authentication Confirmation', body, 'OK', 'Cancel', onSubmit);
    },
    no: function () {
      form.dataset.bypassAuthy = 'true';
      form.submit();
    },
  });

  return false;
};

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('form[data-authy]').forEach((element) => {
    element.addEventListener('submit', submitHandler, false);
  });
});
