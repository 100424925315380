import React, { useRef, useState } from 'react';
import cn from 'classnames';

import { Icon, LoadingSpinner } from '@components';

interface FileUploadProps extends React.HTMLProps<HTMLInputElement> {
  id: string;
  name: string;
  description: string;
  disabled: boolean;
}

type FileUploadItemProps = {
  fileName: string;
  fileSize: string;
  error: boolean;
  errorMessage: string;
  uploading: boolean;
};

const FileUpload = ({
  id,
  name,
  onChange,
  description,
  disabled,
  ...restProps
}: FileUploadProps) => {
  const inputRef = useRef(null);

  const [dragActive, setDragActive] = useState<boolean>(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange?.(e.dataTransfer.files);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onChange?.(e.target.files);
    }
  };

  return (
    <form
      className="file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <label
        className={cn(
          'flex sp-input sp-input-file-upload-wrapper flex-col items-center justify-center sp-input-field-div-wrapper ',
          {
            'sp-input-file-upload-disabled': disabled,
            'sp-input-file-upload cursor-pointer': !disabled,
          },
        )}
      >
        <input
          className="upload"
          ref={inputRef}
          name={name}
          type="file"
          id={id}
          onChange={handleChange}
          disabled={disabled}
          {...restProps}
        />
        <div className="sp-input-file-upload-icon-wrapper-default">
          <Icon
            hoverColor={undefined}
            size="md"
            name="upload"
            color="text-sp-neutral-600"
            className=" sp-input-file-icon-upload"
          />
        </div>

        <p className="text-sm font-medium">
          <span className="text-sp-primary">Click to upload</span>
          <span className="text-sp-neutral-500"> or drag and drop</span>
        </p>
        <p className="text-xs text-sp-neutral-500 mt-1">
          {description ? <span>{description}</span> : null}
        </p>
      </label>

      {dragActive && (
        <div
          className="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};

export const FileUploadItem = ({
  fileName,
  fileSize,
  error,
  errorMessage,
  uploading,
}: FileUploadItemProps) => {
  let progressBar;
  if (uploading) {
    //TO DO: Progress bar UI and chunk up upload progress
    //Temporary UI to show it's being uploaded
    progressBar = <LoadingSpinner />;
  }

  return (
    <div
      className={cn('sp-file-upload-item', {
        ' border-sp-neutral-200 bg-white': !error,
        'border-sp-error-300 bg-sp-error-25': error,
      })}
    >
      <div
        className={cn({
          'sp-input-file-upload-icon-wrapper-default': !error,
          'sp-input-file-upload-icon-wrapper-error': error,
        })}
      >
        <Icon
          hoverColor={undefined}
          size="md"
          name="upload"
          color={cn({
            'text-sp-primary-600': !error,
            'text-sp-error-600': error,
          })}
          className="sp-input-file-icon-upload"
        />
      </div>
      <div>
        {!error || (uploading && !error) ? (
          <dl>
            <dt className="text-sm font-medium text-sp-neutral break-all">
              {fileName}
            </dt>
            <dt className="text-sm text-sp-neutral-500">{fileSize}</dt>
            {uploading && progressBar}
          </dl>
        ) : null}

        {error && (
          <dl>
            <dt className="text-sm text-sp-error font-medium">
              Upload failed, please try again
            </dt>
            <dt className="text-sm text-sp-error-600">{fileName}</dt>
            <dt className="text-sm text-sp-error font-medium my-1">
              {errorMessage}
            </dt>
            <dt className="text-sm text-sp-error text-underline font-semibold">
              Try again
            </dt>
          </dl>
        )}
      </div>
    </div>
  );
};

FileUpload.Item = FileUploadItem;

FileUpload.defaultProps = {
  disabled: false,
};

export default FileUpload;
