import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import FocusTrap from 'focus-trap-react';

const ModalContent = ({
  children,
  maxWidth,
  onClose,
  onKeyDown,
  preventScroll,
  defaultWidth,
  className,
}: {
  children: React.ReactNode;
  maxWidth: string;
  onClose: any;
  onKeyDown: any;
  preventScroll: boolean;
  defaultWidth?: string;
  className?: string;
}) => {
  return (
    <FocusTrap
      focusTrapOptions={{
        preventScroll: preventScroll,
        allowOutsideClick: true,
      }}
    >
      <aside
        className="fixed inset-0 left-0 top-0 z-40 h-full w-full overflow-y-auto"
        role="dialog"
        aria-modal={true}
        tabIndex={-1}
        onKeyDown={onKeyDown}
        id="modal"
      >
        <div className="fixed grid h-screen min-h-full w-full place-items-center">
          <div
            className="bg-sp-neutral-900 fixed inset-0 h-full w-full opacity-50 transition-opacity"
            onClick={(e) => onClose()}
          ></div>
          <div
            className={`relative mx-auto my-8 h-auto max-h-screen overflow-auto rounded-xl bg-white shadow-lg ${defaultWidth} min-w-auto ${maxWidth} ${className}`}
          >
            {children}
          </div>
        </div>
      </aside>
    </FocusTrap>
  );
};

const Modal = ({
  onClose,
  open,
  children,
  maxWidth,
  defaultModal,
  preventScroll,
  defaultWidth,
  className,
}) => {
  const onKeyDown = ({ keyCode }) => {
    return keyCode === 27 && onClose();
  };

  const modalContent = (
    <ModalContent
      onClose={onClose}
      onKeyDown={onKeyDown}
      maxWidth={maxWidth}
      preventScroll={preventScroll}
      className={className}
      defaultWidth={defaultWidth}
    >
      {children}
    </ModalContent>
  );

  useEffect(() => {
    if (open) {
      document.querySelector('body')?.classList.add('toggle-lock-scroll');
    } else {
      document.querySelector('body')?.classList.remove('toggle-lock-scroll');
    }
    return () => {
      document.querySelector('body')?.classList.remove('toggle-lock-scroll');
    };
  }, [open]);

  if (!open) {
    return null;
  }

  if (!defaultModal) {
    return modalContent;
  }

  return createPortal(modalContent, document.body);
};

Modal.defaultProps = {
  maxWidth: '',
  role: 'dialog',
  defaultModal: true,
  preventScroll: false,
  defaultWidth: 'w-1/2',
  className: '',
};

export default Modal;
