import React from 'react';

const TitledContainerBlock = ({ classes, title, label, children }) => {
  return (
    <div className={`my-5 ${classes}`}>
      <div className="shadow border border-sp-neutral-900 rounded-md bg-white p-4">
        <header>
          <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            {title}
            <span className="mt-1 ml-2 inline-block text-xs leading-5 text-gray-500 h-8">
              {label}
            </span>
          </h2>
        </header>
        {children}
      </div>
    </div>
  );
};

TitledContainerBlock.defaultProps = {
  classes: '',
  title: '',
  label: '',
};

export default TitledContainerBlock;
