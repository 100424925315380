import React from 'react';
import { CtrTable } from './CtrTable';
import { Chart } from '../ui-components/Chart';
import { Alert, LoadingSpinner } from '@/js/components';
import { CTRData } from '../types';

export const CtrSection = ({
  showTable,
  data,
  isLoading,
  error,
}: {
  showTable: boolean;
  data: CTRData | undefined;
  isLoading: boolean;
  error: Error | null;
}) => {
  if (isLoading) {
    return (
      <div className="text-center">
        <LoadingSpinner size="md" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert
        variant="error"
        message="There was an error fetching the data. Please contact SearchPilot."
      />
    );
  }

  return showTable && data ? (
    <CtrTable data={data} />
  ) : (
    <>
      <p className="text-sp-neutral-900 mb-8 text-center text-lg font-semibold">
        Average CTR Summary
      </p>
      <Chart
        yText="CTR"
        categories={['Control', 'Variant']}
        series={[
          {
            name: 'Pre-intervention',
            data: [
              data?.metric_summary?.normalised_ctr_control?.[
                'pre-intervention'
              ] ?? 0,
              data?.metric_summary?.normalised_ctr_variant?.[
                'pre-intervention'
              ] ?? 0,
            ],
          },
          {
            name: 'Post-intervention',
            data: [
              data?.metric_summary?.normalised_ctr_control?.[
                'post-intervention'
              ] ?? 0,
              data?.metric_summary?.normalised_ctr_variant?.[
                'post-intervention'
              ] ?? 0,
            ],
          },
        ]}
      />
    </>
  );
};
