import { useState, useEffect } from 'react';
import { getJson } from '@utils';

const getClientList = async () => {
  const url = `/api/v2/accounts/client/list`;
  const res = await getJson(url, {});
  return res;
};

// Hook
const useClients = () => {
  const [clientsListError, setClientsListError] = useState('');
  const [clients, setClients] = useState([]);
  useEffect(() => {
    getClientList()
      .then((res) => setClients(res))
      .catch((e) => {
        setClientsListError('There was an error fetching the data');
      });
  }, []);

  return { clients, setClientsListError, clientsListError };
};

export default useClients;
