import React from 'react';
import cn from 'classnames';
import { error, warning, success, primary, neutral } from '@colors';

type RatingBoxProps = {
  value: number;
  onClickHandler?: () => void;
};

const getWholeScoreColour = (score) => {
  let colors = [
    'bg-sp-error-800',
    'bg-sp-error',
    'bg-sp-warning',
    'bg-sp-success',
    'bg-sp-success-800',
  ];

  switch (true) {
    case score > 0 && score <= 1:
      return colors[0];

    case score > 1 && score <= 2:
      return colors[1];

    case score > 2 && score <= 3:
      return colors[2];

    case score > 3 && score <= 4:
      return colors[3];

    case score > 4 && score <= 5:
      return colors[4];

    default:
      return 'bg-sp-neutral-200';
  }
};

const getHalfScoreColour = (score) => {
  let colors = [
    error['800'],
    error['DEFAULT'],
    warning['DEFAULT'],
    success['DEFAULT'],
    success['800'],
  ];

  switch (true) {
    case score > 0 && score <= 1:
      return colors[0];

    case score > 1 && score <= 2:
      return colors[1];

    case score > 2 && score <= 3:
      return colors[2];

    case score > 3 && score <= 4:
      return colors[3];

    case score > 4 && score <= 5:
      return colors[4];

    default:
      return;
  }
};

const checkTwoDecimalPlaces = (value) => {
  let decimal = value - Math.floor(value);
  return parseFloat(decimal.toFixed(2));
};

const RatingBox = ({ value, onClickHandler }: RatingBoxProps) => {
  const totalScore = 5;
  const activeScore = value;

  const wholeScoreColor = getWholeScoreColour(value);
  const halfScoreColor = getHalfScoreColour(value);

  const decimalPlaces = checkTwoDecimalPlaces(activeScore);

  let halfScoreNumber;
  if (decimalPlaces <= 0.29 && decimalPlaces > 0.0) {
    halfScoreNumber = Math.floor(activeScore);
  } else if (decimalPlaces > 0.7 && decimalPlaces <= 1) {
    halfScoreNumber = Math.round(activeScore);
  } else {
    halfScoreNumber = activeScore;
  }
  return (
    <div className="flex items-end">
      {[...new Array(totalScore)].map((arr, index) => {
        const halfScoreStyle =
          halfScoreNumber > index &&
          halfScoreNumber < index + 1 &&
          `linear-gradient(to right, ${halfScoreColor} 50%,${neutral['200']} 50%)`;
        return (
          <div
            onClick={onClickHandler}
            key={index}
            className={cn('mx-px h-4 w-4', {
              [`${wholeScoreColor}`]:
                halfScoreNumber === index + 1 || index + 1 < halfScoreNumber,

              'bg-sp-neutral-200':
                index + 1 > halfScoreNumber ||
                halfScoreNumber < 0 ||
                halfScoreNumber > 5,
            })}
            style={{
              background: halfScoreStyle || '',
            }}
          ></div>
        );
      })}
    </div>
  );
};

RatingBox.defaultProps = {
  value: 0,
};

export default RatingBox;
