import React from 'react';
import cn from 'classnames';

import { Icon, Tooltip } from '@components';
import { IconNamesType } from '../Icon';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';
import { mapSolidVariant } from './SolidButton';
import { mapOutlinedVariant } from './OutlineButton';
import PlainButton, { mapPlainVariant } from './PlainButton';

type IconButtonBaseProps = Pick<
  ButtonBaseProps,
  'disabled' | 'onClick' | 'style' | 'type'
>;

interface IconButtonProps extends IconButtonBaseProps {
  kind: 'solid' | 'outline' | 'plain';
  variant: 'primary' | 'danger' | 'ghost';
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  iconName: IconNamesType;
  tooltipText?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const mapBaseSize = {
  sm: '8px',
  md: '10px',
  lg: '12px',
  xl: '14px',
  '2xl': '16px',
};

export const iconColor = {
  solid: {
    primary: 'text-white',
    danger: 'text-white',
  },
  outline: {
    primary: 'text-sp-neutral',
    danger: 'text-sp-error',
  },
  plain: {
    primary: 'text-sp-primary',
    danger: 'text-sp-error',
  },
  ghost: {
    primary: 'text-sp-neutral-500',
  },
};
const btnColor = {
  solid: {
    primary: mapSolidVariant['primary'],
    danger: mapSolidVariant['danger'],
  },
  outline: {
    primary: mapOutlinedVariant['primary'],
    danger: mapOutlinedVariant['danger'],
    ghost:
      'bg-transparent hover:text-sp-neutral-600 hover:bg-white disabled:text-sp-neutral-300',
  },
  plain: {
    primary: mapPlainVariant['primary'],
    danger: mapPlainVariant['danger'],
    ghost:
      'bg-transparent hover:text-sp-neutral-600 hover:bg-white disabled:text-sp-neutral-300',
  },
};

let mapIconSize: { [key: string]: 'sm' | 'xl' | '2xl' } = {
  sm: 'sm',
  md: 'xl',
  lg: 'xl',
  xl: 'xl',
  '2xl': '2xl',
};

const IconButton = ({
  kind,
  variant,
  iconName,
  tooltipPosition,
  tooltipText,
  size,
  style,
  type = 'button',
  ...restProps
}: IconButtonProps) => {
  let iconButton = (
    <ButtonBase
      {...restProps}
      className={cn(btnColor[kind][variant], {
        'shadow-none': kind === 'plain',
        border: kind === 'outline',
      })}
      style={{
        ...style,
        padding: mapBaseSize[size ?? "md"],
      }}
      type={type}
    >
      <Icon
        name={iconName}
        size={mapIconSize[size ?? "md"]}
        color={iconColor[kind][variant]}
      />
    </ButtonBase>
  );

  return (
    <>
      {tooltipText ? (
        <Tooltip message={tooltipText} tooltipPosition={tooltipPosition}>
          {iconButton}
        </Tooltip>
      ) : (
        iconButton
      )}
    </>
  );
};

const IconButtonGhost = (props) => {
  return <IconButton kind="plain" variant="ghost" {...props}></IconButton>;
};

const IconButtonPlain = (props) => {
  return <IconButton kind="plain" {...props}></IconButton>;
};

const IconButtonSolid = (props) => {
  return <IconButton kind="solid" {...props}></IconButton>;
};

const IconButtonOutline = (props) => {
  return <IconButton kind="outline" {...props}></IconButton>;
};

IconButton.Ghost = IconButtonGhost;
IconButton.Plain = IconButtonPlain;
IconButton.Solid = IconButtonSolid;
IconButton.Outline = IconButtonOutline;

IconButton.defaultProps = {
  size: 'md',
};

export default IconButton;
