// create poll function
const poll = (fn, timeout, interval) => {
  const endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = (resolve, reject) => {
    const condition = fn();

    condition.then((result) => {
      if (result === 'success' || result === 'failed') {
        resolve(condition);
      } else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      } else {
        reject(new Error('Timed out'));
      }
    });
  };

  return new Promise(checkCondition);
};

const pendingPublishRows = Array.from(
  document.querySelectorAll('.poll-pending-publish'),
);

// poll each publish note
pendingPublishRows.forEach((element) => {
  const fullURL = window.location.href;

  const statusURL = fullURL.replace('history', 'status');

  const publishID = element.getAttribute('data-publish-id');

  const publishStatusURL = `${statusURL}/${publishID}/`;

  const checkPublishStatus = () => {
    return fetch(publishStatusURL)
      .then((response) => response.json())
      .then((data) => {
        return data.status;
      })
      .catch((error) => console.log(error));
  };

  poll(
    checkPublishStatus,
    30000, // timeout is 30 seconds
    5000, // interval is 5 seconds
  )
    .then((response) => {
      if (response === 'success') {
        element?.querySelector('.publish-complete')?.classList.remove('hidden');
      }
      if (response === 'failed') {
        element?.querySelector('.publish-failed')?.classList.remove('hidden');
      }
    })
    .catch((error) => {
      element?.querySelector('.publish-not-complete')?.classList.remove('hidden');
    })
    .finally(() => {
      element?.querySelector('.publish-in-progress')?.classList.add('hidden');
    });
});
