// the purpose of this script is to stop people from submiting the 2FA token form twice

document.addEventListener(
  'DOMContentLoaded',
  function (e) {
    var checkForm = function (e) {
      var form = e.target;
      form.submit_button.value = 'Submitting form...';
      form.submit_button.disabled = true;
    };

    var form = document.getElementById('2FALoginForm');

    if (form) {
      form.addEventListener('submit', checkForm, false);
    }
  },
  false,
);
