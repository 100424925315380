import React from 'react';
import cn from 'classnames';

import TabsBase, { TabsBaseProps } from './TabsBase';

interface VerticalTabsProps extends Omit<TabsBaseProps, 'vertical'> {
  type: 'button' | 'buttonBorder';
}

const mapVerticalTabs = {
  button: `rounded-md w-36`,
  buttonBorder: `rounded-md w-36`,
};

const VerticalTabs = ({
  type,
  children,
  ...restProps
}: Partial<VerticalTabsProps>) => {
  return (
    <TabsBase
      {...restProps}
      vertical={true}
      type={type}
      className={cn(`my-1 flex flex-col px-3 py-2`, mapVerticalTabs[type ?? 'button'])}
    >
      {children}
    </TabsBase>
  );
};

VerticalTabs.defaultProps = {
  type: 'button',
};

export default VerticalTabs;
