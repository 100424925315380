import React from 'react';
import colors from '@colors';
import ReactHighcharts from 'react-highcharts';
import defaultTheme from 'tailwindcss/defaultTheme';

export const Chart = ({
  categories,
  series,
  yText,
}: {
  categories: string[];
  series: {
    name: string;
    data: string[] | number[];
  }[];
  yText?: string;
}) => {
  const config = {
    chart: {
      type: 'column',
    },
    legend: {
      enabled: true,
      x: 30,
      itemStyle: {
        color: colors.neutral['DEFAULT'],
      },
    },
    title: {
      text: undefined,
      style: {
        fontFamily: defaultTheme.fontFamily.sans,
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        groupPadding: 0,
        pointRange: 0.2,
        maxPointWidth: 42,
        events: {
          legendItemClick: function () {
            return false;
          },
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: yText,
      },
      lineWidth: 0,
    },
    xAxis: {
      categories,
      crosshair: true,
      title: {
        text: 'Bucket',
      },
    },
    series: series.map((item, index) => ({
      ...item,
      borderRadius: 8,
      color:
        index === 0
          ? colors.primary['600']
          : index === 1
            ? colors.warning['300']
            : colors.success['400'],
    })),
    credits: {
      enabled: false,
    },
  };

  return (
    <ReactHighcharts
      config={config}
      domProps={{ id: 'google-insights-chart' }}
    />
  );
};
