import React from 'react';
import cn from 'classnames';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

interface SolidButtonProps
  extends Omit<ButtonBaseProps, 'className' | 'style'> {
  variant: 'primary' | 'danger';
}

export const mapSolidVariant = {
  primary: `sp-btn-action`,
  danger: `sp-btn-action-solid-danger`,
};

const SolidButton = ({ variant, children, ...restProps }: SolidButtonProps) => {
  return (
    <ButtonBase
      {...restProps}
      className={cn('shadow-xs border', mapSolidVariant[variant])}
    >
      {children}
    </ButtonBase>
  );
};

export default SolidButton;
