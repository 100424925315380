import React, { ReactNode } from 'react';
import cn from 'classnames';

export type InfoCardProps = {
  icon?: ReactNode;
  title: ReactNode;
  description: ReactNode;
  link?: {
    text: string;
    url: string;
  };
  className?: string;
};

export const InfoCard = ({
  icon,
  title,
  description,
  link,
  className = '',
}: InfoCardProps) => (
  <div
    className={cn(
      'bg-sp-neutral-50 flex gap-3 rounded-lg px-6 py-5',
      className,
    )}
  >
    {icon}
    <div className="flex flex-col">
      <p className="text-sp-neutral-900 mb-1 text-lg font-medium">{title}</p>
      <p className="text-sp-neutral text-sm font-normal">{description}</p>
      {link && (
        <a
          href={link.url || '#'}
          className="text-sp-primary mt-2 text-sm underline"
          target="_blank"
        >
          {link.text}
        </a>
      )}
    </div>
  </div>
);
