import React, { Children, isValidElement } from 'react';
import cn from 'classnames';

import { useOutsideClick } from '@hooks';

import ButtonGroupBase from './ButtonGroupBase';
import { SolidButton, OutlineButton, IconButton, Icon } from '@components';
import { iconColor } from '@components/Button/IconButton';

const mapTypeToButton = {
  solid: SolidButton,
  outline: OutlineButton,
};

interface SplitButtonBaseProps {
  kind: 'solid' | 'outline';
  size?:
    | React.ComponentProps<typeof SolidButton>['size']
    | React.ComponentProps<typeof OutlineButton>['size'];
  primaryActionLabel: string;
  onClick?: () => void;
  children:
    | React.ReactNode
    | ((params: { closeDropdown: () => void }) => React.ReactNode);
  alignDropdownItems?: 'right' | 'left';
}

const SplitButtons = ({
  alignDropdownItems = 'right',
  size,
  kind,
  primaryActionLabel,
  onClick,
  children,
}: SplitButtonBaseProps) => {
  const { open, setOpen, ref } = useOutsideClick();

  let Elem = mapTypeToButton[kind];

  return (
    <div className="relative block" ref={ref}>
      {onClick ? (
        <>
          <ButtonGroupBase groupType="split">
            <Elem
              variant="primary"
              onMouseDown={(event) => event.preventDefault()}
              onClick={onClick}
              size={size}
            >
              {primaryActionLabel}
            </Elem>
            <IconButton
              iconName="chevronDown"
              kind={kind}
              variant="primary"
              onClick={() => setOpen(!open)}
            />
          </ButtonGroupBase>
        </>
      ) : (
        <Elem
          variant="primary"
          size={size}
          onMouseDown={(event) => event.preventDefault()}
          onClick={(event) => {
            event.preventDefault();
            setOpen(!open);
          }}
          rightIcon={
            <Icon name="chevronDown" color={iconColor[kind].primary} />
          }
        >
          {primaryActionLabel}
        </Elem>
      )}

      {open && (
        <div
          className={cn(
            'absolute z-50 w-56 mt-2 bg-white border rounded-lg shadow-lg border-sp-neutral-100',
            {
              'right-0': alignDropdownItems === 'right',
              'left-0': alignDropdownItems === 'left',
            },
          )}
        >
          {typeof children === 'function'
            ? children({ closeDropdown: () => setOpen(false) })
            : children}
        </div>
      )}
    </div>
  );
};

export const SplitButtonItem = ({
  children,
  onClick,
  disabled,
  className,
}: {
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
  disabled: boolean;
  className: string;
}) => {
  return (
    <a
      onClick={onClick}
      className={cn(
        `sp-dropdown-menu-item text-sp-neutral`,
        {
          'text-sp-neutral-200 pointer-events-none': disabled,
        },
        className,
      )}
    >
      {children}
    </a>
  );
};

SplitButtons.Item = SplitButtonItem;

SplitButtons.defaultProps = {
  kind: 'solid',
};

SplitButtonItem.defaultProps = {
  disabled: false,
  className: null,
};

export default SplitButtons;
