import React from 'react';
import cn from 'classnames';

import { Icon } from '@components';

type AvatarProps = {
  initials?: string;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
};

const Avatar = ({ initials, size }: AvatarProps) => {
  let iconSize = size === 'xs' ? 'sm' : size;
  return (
    <div
      className={cn(
        'bg-sp-neutral-50 flex items-center justify-center rounded-full',
        {
          'h-6 w-6 text-xs': size === 'xs',
          'h-8 w-8 text-sm': size === 'sm',
          'text-md h-10 w-10': size === 'md',
          'h-12 w-12 text-lg': size === 'lg',
          'h-14 w-14 text-xl': size === 'xl',
          'h-16 w-16 text-2xl': size === '2xl',
        },
      )}
    >
      <span className="text-sp-neutral-600">
        {initials ? initials : <Icon name="FiUser" size={iconSize} />}
      </span>
    </div>
  );
};

Avatar.defaultProps = {
  size: 'md',
};

export default Avatar;
