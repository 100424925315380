import React, {
  useEffect,
  useCallback,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import cn from 'classnames';

import { Icon, IconButton, Avatar } from '@components';
import { useOutsideClick } from '@hooks';
import ButtonBase from '../Button/ButtonBase';
import { IconNamesType } from '../Icon';

interface IMenuDropdownContext {
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const MenuDropdownContext = createContext<IMenuDropdownContext>({
  setOpen: () => {},
});

export const MenuDropdownItem = ({
  tabIndex,
  label,
  leftIcon,
  onClick,
}: {
  tabIndex?: number;
  label: string;
  leftIcon?: IconNamesType;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}): JSX.Element => {
  const { setOpen } = useContext(MenuDropdownContext);
  const handleClick = (e) => {
    setOpen(false);
    onClick(e);
  };

  return (
    <a
      tabIndex={tabIndex}
      role="option"
      key={label}
      onClick={handleClick}
      className={cn(`sp-dropdown-menu-item text-sp-neutral`)}
    >
      {leftIcon && <Icon name={leftIcon} />}
      {label}
    </a>
  );
};

export const MenuDropdown = ({
  text,
  iconName,
  children,
  iconOnly,
  avatarInitials,
}: {
  text?: string;
  iconName: IconNamesType;
  children: React.ReactNode;
  iconOnly?: boolean;
  avatarInitials?: string;
}): JSX.Element => {
  const { ref, open, setOpen } = useOutsideClick();

  const escape = useCallback((event) => {
    if (event.keyCode === 27) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escape);
    return () => {
      document.removeEventListener('keydown', escape);
    };
  }, [escape]);

  return (
    <span ref={ref}>
      {!iconOnly && !avatarInitials && (
        <ButtonBase
          onClick={() => setOpen(!open)}
          rightIcon={<Icon name={iconName} />}
          aria-haspopup="listbox"
          aria-label="menudropdown-btn"
          className="justify-between w-full p-4"
        >
          {text && (
            <span className="text-sp-neutral-800 font-semibold">{text}</span>
          )}
        </ButtonBase>
      )}
      {iconOnly && (
        <IconButton.Ghost iconName={iconName} onClick={() => setOpen(!open)} />
      )}
      {avatarInitials && (
        <ButtonBase
          onClick={() => setOpen(!open)}
          aria-haspopup="listbox"
          aria-label="menudropdown-btn"
          className="p-0 rounded-full focus:ring-4 focus:ring-sp-neutral-100"
        >
          <Avatar size="md" initials={avatarInitials} />
        </ButtonBase>
      )}

      <span className="-ml-px relative block">
        {open && (
          <div
            className={`absolute bg-white mt-2 border border-sp-neutral-100 shadow-lg rounded-lg w-full min-w-max right-0 z-50 max-h-72 overflow-y-auto`}
            role="listbox"
          >
            <MenuDropdownContext.Provider value={{ setOpen }}>
              {children}
            </MenuDropdownContext.Provider>
          </div>
        )}
      </span>
    </span>
  );
};

MenuDropdown.Item = MenuDropdownItem;

MenuDropdown.defaultProps = {
  iconName: 'chevronDown',
  iconOnly: false,
  avatarPlaceholder: null,
};
