import { useContext } from 'react';
import ToastContext, { IToastContext } from './context';

const useToast = (): IToastContext => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return {
    warning: context.warning,
    info: context.info,
    success: context.success,
    error: context.error,
    remove: context.remove,
  };
};

export default useToast;
