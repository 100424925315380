import React from 'react';
import cn from 'classnames';

import { Icon } from '@components';
import { IconNamesType } from '../Icon';

type Size = 'sm' | 'md' | 'lg';
export type Colour =
  | 'red'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'gray'
  | 'purple'
  | 'dark-purple'
  | 'flashy-purple';

const mapPillColour = {
  red: 'bg-sp-error-100 text-sp-error-800',
  yellow: 'bg-sp-warning-100 text-sp-warning-800',
  green: 'bg-sp-success-100 text-sp-success-800',
  blue: 'bg-sp-secondary-100 text-sp-secondary-800',
  gray: 'bg-sp-neutral-100 text-sp-neutral-800',
  purple: 'bg-sp-primary-100 text-sp-primary-800',
  'dark-purple': 'bg-sp-primary-800 text-sp-primary-100',
  'flashy-purple':
    'bg-gradient-to-r from-sp-pink-400 to-sp-primary-500 text-sp-primary-100',
};

const PillIcon = ({
  icon,
  onClick,
  colour,
}: {
  icon: IconNamesType;
  onClick: () => void;
  colour: string;
}) => {
  return (
    <div className={cn('w-3')} onClick={onClick}>
      <Icon
        name={icon}
        size={'sm'}
        hoverColor={undefined}
        color={mapPillColour[colour]}
      />
    </div>
  );
};

const Pill = ({
  text,
  colour,
  leftIcon,
  rightIcon,
  onIconClick,
  size = 'sm',
}: {
  text?: string;
  colour: Colour;
  leftIcon?: IconNamesType;
  rightIcon?: IconNamesType;
  onIconClick?: () => void;
  size?: Size;
}) => {
  return (
    <span
      className={cn(
        `inline-flex items-center justify-center rounded-full text-xs font-medium leading-4`,
        mapPillColour[colour],
        {
          'px-2 py-0.5': size === 'sm',
          'px-2.5 py-0.5': size === 'md',
          'px-3 py-1': size === 'lg',
        },
      )}
    >
      <div className={`flex items-center justify-center gap-1 p-0`}>
        {leftIcon ? (
          <span className="cursor-pointer">
            <PillIcon icon={leftIcon} onClick={onIconClick || (() => {})} colour={colour} />
          </span>
        ) : null}

        {text ? <span>{text}</span> : null}

        {rightIcon ? (
          <span className="cursor-pointer">
            <PillIcon icon={rightIcon} onClick={onIconClick || (() => {})} colour={colour} />
          </span>
        ) : null}
      </div>
    </span>
  );
};

export default Pill;
