import React from 'react';

import {
  LightCard,
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '@components';
import { CTRData } from '../types';

export const CtrTable = ({ data }: { data: CTRData }) => (
  <LightCard className="overflow-hidden">
    <Table>
      <thead>
        <TableHeaderCell
          colSpan={4}
          className="text-sp-neutral-900 bg-transparent text-lg font-medium capitalize"
        >
          Average CTR Summary
        </TableHeaderCell>
        <TableHeaderRow>
          <TableHeaderCell />
          <TableHeaderCell className="capitalize">
            Pre-Intervention
          </TableHeaderCell>
          <TableHeaderCell className="capitalize">
            Post Intervention
          </TableHeaderCell>
          <TableHeaderCell className="capitalize">
            Absolute Difference
          </TableHeaderCell>
        </TableHeaderRow>
      </thead>
      <tbody>
        <TableRow>
          <TableCell>Variant CTR</TableCell>
          <TableCell>
            {data?.metric_summary?.normalised_ctr_variant?.['pre-intervention']}
          </TableCell>
          <TableCell>
            {data?.metric_summary.normalised_ctr_variant?.['post-intervention']}
          </TableCell>
          <TableCell>
            {data.metric_summary?.normalised_ctr_variant?.difference}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Control CTR</TableCell>
          <TableCell>
            {data.metric_summary?.normalised_ctr_control?.['pre-intervention']}
          </TableCell>
          <TableCell>
            {data.metric_summary?.normalised_ctr_control?.['post-intervention']}
          </TableCell>
          <TableCell>
            {data.metric_summary?.normalised_ctr_control?.difference}
          </TableCell>
        </TableRow>
      </tbody>
    </Table>
  </LightCard>
);
