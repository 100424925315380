import React from 'react';
import cn from 'classnames';

import { IconsList, IconsType } from './IconsList';

const mapIconSize = {
  sm: 'text-xs',
  md: 'text-sm',
  lg: 'text-base',
  xl: 'text-xl',
  '2xl': 'text-2xl',
};

export type IconSizesType = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type IconNamesType = keyof IconsType;

const Icon = ({
  title,
  name,
  size,
  color,
  hoverColor,
  className,
}: {
  title?: string;
  name: IconNamesType;
  size: IconSizesType;
  color: string;
  hoverColor: string;
  className?: string;
}) => {
  const SVGInnerComponent = IconsList[name];
  if (SVGInnerComponent == undefined) {
    return null;
  }

  let onHoverColor = `hover:${hoverColor}`;

  const iconStyles = `${color} ${onHoverColor}`;

  return (
    <>
      <span
        data-tip={title}
        className={cn(mapIconSize[size], iconStyles, className)}
      >
        {SVGInnerComponent && <SVGInnerComponent />}
      </span>
    </>
  );
};

Icon.defaultProps = {
  size: 'md',
  color: 'text-sp-neutral',
  hoverColor: '',
};

export default Icon;
