import React from 'react';
import cn from 'classnames';
import { Label, HelpText, ErrorText } from '@components';

interface TextAreaProps {
  value: any;
  onChangeFunction: any;
  onChangeParameters: any;
  label: string;
  id: string;
  required: boolean;
  helpText: string | null;
  errorText: string | null;
  placeholder: string;
  onBlurFunction: any | null;
  onBlurParameters: any | null;
  disabled: boolean;
  rows: number;
  className?: string;
  children?: React.ReactNode;
}

const TextArea = ({
  value,
  onChangeFunction,
  onChangeParameters,
  label,
  id,
  required,
  helpText,
  errorText,
  placeholder,
  onBlurFunction,
  onBlurParameters,
  disabled,
  rows,
  className,
  children,
}: TextAreaProps) => {
  const errorStyles =
    'border-red-300 focus:border-red-300 focus:ring-4 focus:ring-red-100';
  return (
    <>
      <Label label={label} id={id} />

      {children}

      <textarea
        id={id}
        value={value}
        onChange={(e) => {
          onChangeFunction(...onChangeParameters, e.target.value);
        }}
        onBlur={() => {
          if (onBlurFunction !== null) {
            onBlurFunction(...onBlurParameters);
          }
        }}
        className={cn(
          `sp-input sp-input-textarea`,
          'mt-1.5 rounded-md shadow-sm',
          className,
          {
            [`${errorStyles}`]: errorText,
          },
        )}
        required={required}
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
      />
      {errorText ? (
        <ErrorText text={errorText} />
      ) : (
        <HelpText>{helpText}</HelpText>
      )}
    </>
  );
};

TextArea.defaultProps = {
  value: '',
  label: '',
  required: false,
  helpText: null,
  errorText: null,
  placeholder: '',
  trailingIcon: null,
  onBlurFunction: null,
  onBlurParameters: null,
  disabled: false,
  rows: 10,
};

export default TextArea;
